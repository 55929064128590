import React from 'react';
import GenFormState from './GenFormState';

// FormGroup
export default function genFormReset() {
  // Reset the form by resetting the items in GenFormState
  GenFormState.formJsxChunks = [];
  GenFormState.fieldGroupArrayInstaces = {};

}
