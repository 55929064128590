import React from 'react';
import { genFormParts_FormGroup, genFormParts_FieldGroup, genFormParts_FieldGroupArray } from './genFormParts';
import GenFormState from './GenFormState';
import { FormGroup, Button } from 'reactstrap';

export {
  genFormPlain,
};

const genFormPlain = function (_this) {
  const { jsonb_schema_form_layout, formData } = GenFormState;
  var chunk = null;
  for(var i = 0, len = jsonb_schema_form_layout.fields.length; i < len; i++){
    var item = jsonb_schema_form_layout.fields[i];
    console.log('genFormPlain item', item)
    if(item.field){
      console.log('genFormPlain field', item.field.label)
      chunk = genFormParts_FormGroup(item.field);
      GenFormState.formJsxChunks.push({[item.field.name] : chunk});
    }else if(item.fieldGroup){
      console.log('genFormPlain fieldGroup', item.fieldgroup.label)
      chunk = genFormParts_FieldGroup(item);
      GenFormState.formJsxChunks.push({[item.field.label] : chunk});
    }else if(item.fieldArray){
      console.log('genFormPlain fieldArray', item.fieldArray.label)
      let instances = 1
      if(formData){
        let max_idx = 0;
        for(let key in formData){
          console.log('genFormPlain key',key);
          if(key.includes(item.fieldArray.name)){
            let re = /\[\s*(\d+)\s*\]/g;
            let match = re.exec(key);
            let idx = parseInt(match[1]);
            if(idx > max_idx) max_idx = idx;
          }
        }
        instances = max_idx + 1;
      }
      for(var j = 0; j < instances; j++){
        genFormParts_FieldGroupArray(item.fieldArray, _this);
      }
    }
  }
  console.log('genFormPlain formJsxChuncks', GenFormState.formJsxChunks)
}

