import React from "react";
import { view } from 'react-easy-state';
import { Accordion, Card, Button, Form, Row, Col, Table, Modal } from 'react-bootstrap'
import { SendRequest } from 'rtcmesh-react';
import luhn from 'fast-luhn';
import Header from "../partials/Header";
import Footer from "../partials/Footer";
import AppState from '../../support/AppState';
import { init_order, order_validate_address, order_set_valid_address, order_place_order, order_local_reset } from '../../support/order';
import { cart_local_reset } from '../../support/cart';

class Checkout extends React.Component {
  
  constructor(props) {
    if(process.env.REACT_APP_TRACE_FN_SEQ) console.log('=*= Checkout constructor');
    super(props);
    this.state = {activePanel : '1', address : {country:'US'}, payment : {}, modalIsOpen: false}
  }
  
  componentDidMount(){
    if(process.env.REACT_APP_TRACE_FN_SEQ) console.log('=*= Checkout componentDidMount');
    // Send the user to the Sign In page and set redirection if the user is anonymous.
    // We set it on a timer to allow user to be set in AppState
    setTimeout(function(history){
      const { user } = AppState;
      if(! user){
        AppState.redirect_login_url = '/checkout';
        history.push('/signin');   // router 4 sets history
      }
    }, 200, this.props.history);
    // Retrieve or create the order for this cart.
    let _this = this;
    init_order(function(){
      if(AppState.order){
        console.debug('componentDidMount init_order order', AppState.order)
        if(AppState.order.address){
          let address = Object.assign({}, _this.state.address);  // creating a copy of the object
          address.is_valid = true;
          address.full_name = AppState.order.address.full_name;
          address.address_line1 = AppState.order.address.Address1;
          address.address_line2 = AppState.order.address.Address2;
          address.city = AppState.order.address.City;
          address.state = AppState.order.address.State;
          address.postal_code = AppState.order.address.Zip5 + '-' + AppState.order.address.Zip4;
          console.debug('componentDidMount init_order address', address)
          _this.setState({address});
          _this.setState({activePanel : '2'})
        }
      }
    });
  }
  
  // Address
  handleChange_address = (event) => {
    if(process.env.REACT_APP_TRACE_FN_SEQ) console.log('=*= Checkout handleChange_address');
    let address = Object.assign({}, this.state.address);  // creating a copy of the object
  	if(event.target.type === 'checkbox'){
  		address[event.target.name] = event.target.checked;	
  	}else{
      address[event.target.name] = event.target.value;
  	}
    address.is_valid = false;
    this.setState({address});
  }
  validate_address = (event) => {
    if(process.env.REACT_APP_TRACE_FN_SEQ) console.log('=*= Checkout validate_address');
    event.preventDefault();
    // Set the address.
    console.debug('address', this.state.address)
    // Pop the modal with the wait gif and then send a message to validate the address using the USPS API
    const { assets_url_prefix } = AppState;
    let _this = this, theJsx = (<div className="text-center"><img src={assets_url_prefix + 'wait.webp'} /></div>)
    this.setState({modal_content_jsx : theJsx});
    this.setState({modal_title :'Shipping Address'});
    this.showModal();
    order_validate_address(this.state.address, function(response){
      console.log('Checkout validate_address order_validate_address response', response);
      // Show address or error to user
      if(response.code === 200 && response.data && response.data.DPVConfirmation === 'Y'){
        // Confirm.
        let theJsx = (
          <div>
            <h5 className="text-center">We have validated the following address:</h5>
            <div>{response.data.full_name}</div>
            <div>{response.data.Address1}</div>
            <div>{response.data.Address2 ? response.data.Address2 : ''}</div>
            <div>{response.data.City}, {response.data.State} {response.data.Zip5}-{response.data.Zip4}</div>
            <div className="mt-3">
              <Button variant="dark" onClick={() => _this.set_valid_address(response.data)}>Accept Address</Button>&nbsp;   
              <Button variant="dark" onClick={_this.hideModal}>Edit Address</Button>   
            </div>
          </div>
        )
        _this.setState({modal_content_jsx : theJsx});
      }else{
        // Show error.
        let theJsx = (
          <div>
            <h5 className="text-center">We could not validate the address</h5>
            <div>{response.message}</div>  
            <Button variant="dark" onClick={_this.hideModal} className="mt-3">Edit Address</Button>   
          </div>
        )
        _this.setState({modal_content_jsx : theJsx});        
      }
    });
  }
  set_valid_address = (valid_address) => {
    if(process.env.REACT_APP_TRACE_FN_SEQ) console.log('=*= Checkout set_valid_address');
    // Change the modal content to the wait gif and then send a message to validate the address using the USPS API
    const { assets_url_prefix } = AppState;
    let _this = this, theJsx = (<div className="text-center"><img src={assets_url_prefix + 'wait.webp'} /></div>)
    this.setState({modal_content_jsx : theJsx});
    // Set validated address and set is_valid to true.
    order_set_valid_address(valid_address, function(response){
      console.debug('Checkout set_valid_address order_set_valid_address response', response);
      _this.hideModal();
      if(response.code === 200){
        let address = Object.assign({}, _this.state.address);  // creating a copy of the object
        address.is_valid = true;
        address.full_name = valid_address.full_name;
        address.address_line1 = valid_address.Address1;
        address.address_line2 = valid_address.Address2;
        address.city = valid_address.City;
        address.state = valid_address.State;
        address.postal_code = valid_address.Zip5 + '-' + valid_address.Zip4;
        _this.setState({address});
        // Go to Review or Payment method.
        if(_this.state.payment.is_valid){
          _this.set_activePanel('3');
        }else{
          _this.set_activePanel('2');
        }
      }else{
        const { showAlertBanner } = AppState;
        showAlertBanner('danger', "Error setting the address, please retry.", 8000);
      }
    });
  }
  
  // Payment
  handleChange_payment = (event) => {
    if(process.env.REACT_APP_TRACE_FN_SEQ) console.log('=*= Checkout handleChange_payment');
    let payment = Object.assign({}, this.state.payment);  // creating a copy of the object
  	if(event.target.type === 'checkbox'){
  		payment[event.target.name] = event.target.checked;	
  	}else{
      payment[event.target.name] = event.target.value;
  	}
    payment.is_valid = false;
    this.setState({payment});
  }
  validate_card = (event) => {
    if(process.env.REACT_APP_TRACE_FN_SEQ) console.log('=*= Checkout validate_card');
    event.preventDefault();
    // Validate the CC number using luhn and then get an authorization code.
    if(luhn(this.state.payment.card_number)){
      // Check the expiration idate.
      const exp = parseInt(this.state.payment.card_exp_yr + this.state.payment.card_exp_mo);
      const today = new Date();
      const this_month = parseInt(today.getFullYear().toString() + (today.getMonth() + 1).toString().padStart(2, '0'))
      console.debug('exp', exp, '- this_month', this_month)
      if(exp - this_month >= 0){
        let payment = Object.assign({}, this.state.payment);  // creating a copy of the object
        payment.is_valid = true;
        this.setState({payment});
        // Go to Review method.
        this.set_activePanel('3');
      }else{
        const { showAlertBanner } = AppState;
        showAlertBanner('danger', "Invalid expiration date, please correct.", 8000);
      }
    }else{
      const { showAlertBanner } = AppState;
      showAlertBanner('danger', "Invalid Card number, please correct.", 8000);
    }
  }
  place_order = () => {
    if(process.env.REACT_APP_TRACE_FN_SEQ) console.log('=*= Checkout place_order');
    
    let payment = this.state.payment;
    payment.customer_email = AppState.user.attributes.email;
    payment.amount = AppState.cart.total;
    payment.order_id = AppState.order.id;
    if(payment.billing_is_shipping){
      payment.address = this.state.address.address_line1 + (this.state.address.address_line2 ? this.state.address.address_line2 : '');
      payment.city = this.state.address.city;
      payment.state = this.state.address.state;
      payment.zip = this.state.address.postal_code;
    }else{
      payment.address = payment.address_line1 + (payment.address_line2 ? payment.address_line2 : '');
      payment.zip = payment.postal_code;
    }
    console.debug('payment', payment)
    // Pop the modal with the wait gif and then send a message to validate the address using the USPS API
    const { assets_url_prefix } = AppState;
    let _this = this, theJsx = (<div className="text-center"><img src={assets_url_prefix + 'wait.webp'} /></div>)
    this.setState({modal_content_jsx : theJsx});
    this.setState({modal_title :'Placing Order'});
    this.showModal();
    order_place_order(payment,  function(response){
      console.debug('Checkout place_order order_place_order response', response);
      if(response.code === 200){
        let order_status_url = '/orderstatus/' + AppState.order.id + '?new=Y';
        cart_local_reset();
        order_local_reset();
        _this.hideModal();
        const {history} = AppState;   // history set in SiteNav
        history.push(order_status_url);
      }else{
        theJsx = (
          <div>
            <div className="text-center">{response.message}</div>
            <Button variant="dark" className="mt-3" onClick={_this.hideModal}>Continue</Button> 
          </div>
        );
        _this.setState({modal_content_jsx : theJsx});
      }
    });
  }
  
  set_activePanel = (eventKey) => {
    if(process.env.REACT_APP_TRACE_FN_SEQ) console.log('=*= Checkout set_activePanel');
    this.setState({activePanel : eventKey});
  }
  
  showModal = () => {
    this.setState({ modalIsOpen: true });
  };
  hideModal = () => {
    this.setState({ modalIsOpen: false });
  };
  
	render() {
		if(process.env.REACT_APP_TRACE_FN_SEQ) console.log('=*= Checkout render');
    const { user, assets_url_prefix } = AppState;
    let card_number_show = this.state.payment.card_number ? this.state.payment.card_number.slice(-4).padStart(this.state.payment.card_number.length, '*') : '';
	  let card_exp_show = this.state.payment.card_exp_yr ? this.state.payment.card_exp_mo + '/' + this.state.payment.card_exp_yr.slice(-2) : '';
    return (
      <div>
        <Header />
			  <div className="content">
          <Modal show={this.state.modalIsOpen} backdrop="static" onHide={this.hideModal}>
            <Modal.Header closeButton>
              <Modal.Title>{this.state.modal_title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.state.modal_content_jsx}
            </Modal.Body>
          </Modal>
          <h3>Checkout</h3>
          <Row className="mt-2">
            <Col sm={12} md={8}>
              <Accordion activeKey={this.state.activePanel}>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="1" onClick={() => this.set_activePanel('1')} className="accordion-header">
                      1. Shipping address
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="1">
                    <Card.Body>
                      <Form onSubmit={ this.validate_address }>
                        <Form.Group>
                          <Form.Label>Country/Region</Form.Label>
                          <Form.Control as="select" defaultValue="US" name="country" onChange={this.handleChange_address} required>
                            <option value="US">United States</option>
                          </Form.Control>
          			        </Form.Group>
                        <Form.Group>
          			          <Form.Label for="full_name">Full name</Form.Label>
          			          <Form.Control type="text" name="full_name" placeholder="" defaultValue={this.state.address.full_name}
                            onChange={this.handleChange_address} required />
          			        </Form.Group>
          			        <Form.Group>
          			          <Form.Label for="address_line1">Address line 1</Form.Label>
          			          <Form.Control type="text" name="address_line1" defaultValue={this.state.address.address_line1} 
                            onChange={this.handleChange_address} required />
          			        </Form.Group>
          			        <Form.Group>
          			          <Form.Label for="address_line2">Address line 2</Form.Label>
          			          <Form.Control type="text" name="address_line2" placeholder="" defaultValue={this.state.address.address_line2}
                            onChange={this.handleChange_address} />
          			        </Form.Group>
                        <Form.Row>
                          <Form.Group as={Col} controlId="formGridCity">
                            <Form.Label>City</Form.Label>
                            <Form.Control name="city" defaultValue={this.state.address.city} onChange={this.handleChange_address} required/>
                          </Form.Group>
                          <Form.Group as={Col} controlId="formGridState">
                            <Form.Label>State</Form.Label>
                            <Form.Control as="select" name="state" value={this.state.address.state ? this.state.address.state : '"Choose..."'}
                              onChange={this.handleChange_address} required>
                              <option value="">Choose...</option>
                            	<option value="AL">Alabama (AL)</option>
                            	<option value="AK">Alaska (AK)</option>
                            	<option value="AZ">Arizona (AZ)</option>
                            	<option value="AR">Arkansas (AR)</option>
                            	<option value="CA">California (CA)</option>
                            	<option value="CO">Colorado (CO)</option>
                            	<option value="CT">Connecticut (CT)</option>
                            	<option value="DE">Delaware (DE)</option>
                            	<option value="DC">District Of Columbia (DC)</option>
                            	<option value="FL">Florida (FL)</option>
                            	<option value="GA">Georgia (GA)</option>
                            	<option value="HI">Hawaii (HI)</option>
                            	<option value="ID">Idaho (ID)</option>
                            	<option value="IL">Illinois (IL)</option>
                            	<option value="IN">Indiana (IN)</option>
                            	<option value="IA">Iowa (IA)</option>
                            	<option value="KS">Kansas (KS)</option>
                            	<option value="KY">Kentucky (KY)</option>
                            	<option value="LA">Louisiana (LA)</option>
                            	<option value="ME">Maine (ME)</option>
                            	<option value="MD">Maryland (MD)</option>
                            	<option value="MA">Massachusetts (MA)</option>
                            	<option value="MI">Michigan (MI)</option>
                            	<option value="MN">Minnesota (MN)</option>
                            	<option value="MS">Mississippi (MS)</option>
                            	<option value="MO">Missouri (MO)</option>
                            	<option value="MT">Montana (MT)</option>
                            	<option value="NE">Nebraska (NE)</option>
                            	<option value="NV">Nevada (NV)</option>
                            	<option value="NH">New Hampshire (NH)</option>
                            	<option value="NJ">New Jersey (NJ)</option>
                            	<option value="NM">New Mexico (NM)</option>
                            	<option value="NY">New York (NY)</option>
                            	<option value="NC">North Carolina (NC)</option>
                            	<option value="ND">North Dakota (ND)</option>
                            	<option value="OH">Ohio (OH)</option>
                            	<option value="OK">Oklahoma (OK)</option>
                            	<option value="OR">Oregon (OR)</option>
                            	<option value="PA">Pennsylvania (PA)</option>
                            	<option value="RI">Rhode Island (RI)</option>
                            	<option value="SC">South Carolina (SC)</option>
                            	<option value="SD">South Dakota (SD)</option>
                            	<option value="TN">Tennessee (TN)</option>
                            	<option value="TX">Texas (TX)</option>
                            	<option value="UT">Utah (UT)</option>
                            	<option value="VT">Vermont (VT)</option>
                            	<option value="VA">Virginia (VA)</option>
                            	<option value="WA">Washington (WA)</option>
                            	<option value="WV">West Virginia (WV)</option>
                            	<option value="WI">Wisconsin (WI)</option>
                            	<option value="WY">Wyoming (WY)</option>
                            </Form.Control>
                          </Form.Group>
                          <Form.Group as={Col} controlId="formGridZip">
                            <Form.Label>Zip</Form.Label>
                            <Form.Control  name="postal_code" defaultValue={this.state.address.postal_code} onChange={this.handleChange_address} required/>
                          </Form.Group>
                        </Form.Row>
                        <Button variant="dark" type="submit">Next</Button>   
                      </Form>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="2" onClick={() => this.set_activePanel('2')} className="accordion-header"
                    disabled={! this.state.address.is_valid ? 'disabled' : ''}>
                      2. Payment method
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="2">
                    <Card.Body>
                      <Form onSubmit={ this.validate_card }>
                        <Form.Group>
          			          <Form.Label for="card_number">Card number</Form.Label>
          			          <Form.Control type="number" name="card_number" placeholder="" defaultValue={this.state.payment.card_number}
                            onChange={this.handleChange_payment} required />
                          <Form.Text>
                            Numbers only, no dashes.
                          </Form.Text>
          			        </Form.Group>
                        <Form.Row>
                          <Form.Group as={Col} controlId="formGridState">
                            <Form.Label>Card Exp. Month</Form.Label>
                            <Form.Control as="select" name="card_exp_mo" value={this.state.payment.card_exp_mo ? this.state.payment.card_exp_mo : '"Choose..."'}
                              onChange={this.handleChange_payment} required>
                              <option value="">Choose...</option>
                              <option value="01">01</option>
                              <option value="02">02</option>
                              <option value="03">03</option>
                              <option value="04">04</option>
                              <option value="05">05</option>
                              <option value="06">06</option>
                              <option value="07">07</option>
                              <option value="08">08</option>
                              <option value="09">09</option>
                              <option value="10">10</option>
                              <option value="11">11</option>
                              <option value="12">12</option>
                            </Form.Control>
                          </Form.Group>
                          <Form.Group as={Col} controlId="formGridState">
                            <Form.Label>Card Exp. Year</Form.Label>
                            <Form.Control as="select" name="card_exp_yr" value={this.state.payment.card_exp_yr ? this.state.payment.card_exp_yr : '"Choose..."'}
                              onChange={this.handleChange_payment} required>
                              <option value="">Choose...</option>
                              <option>2020</option>
                              <option>2021</option>
                              <option>2022</option>
                              <option>2023</option>
                              <option>2024</option>
                              <option>2025</option>
                              <option>2026</option>
                              <option>2027</option>
                              <option>2028</option>
                              <option>2029</option>
                              <option>2030</option>                            
                            </Form.Control>
                          </Form.Group>
                          <Form.Group>
            			          <Form.Label for="card_code">Card code (cvv)</Form.Label>
            			          <Form.Control type="text" name="card_code" placeholder="" defaultValue={this.state.payment.card_code}
                              onChange={this.handleChange_payment} required />
            			        </Form.Group>
                        </Form.Row>
                        <Form.Group>    
                          <Form.Check type="checkbox" name="billing_is_shipping" id="billing_is_shipping" label="Billing address same as shipping" 
                            onChange={this.handleChange_payment} />
                        </Form.Group>   
                { ! this.state.payment.billing_is_shipping &&             
                        <div>
                          <Form.Group>
            			          <Form.Label for="address_line1">Billing address line 1</Form.Label>
            			          <Form.Control type="text" name="address_line1" placeholder="" defaultValue={this.state.payment.address_line1}
                              onChange={this.handleChange_payment} required />
            			        </Form.Group>
                          <Form.Group>
            			          <Form.Label for="address_line2">Billing address line 2</Form.Label>
            			          <Form.Control type="text" name="address_line2" placeholder="" defaultValue={this.state.payment.address_line2}
                              onChange={this.handleChange_payment} />
            			        </Form.Group>                          
                          <Form.Row>
                            <Form.Group as={Col} controlId="formGridCity">
                              <Form.Label>Billing city</Form.Label>
                              <Form.Control name="city" defaultValue={this.state.payment.city} onChange={this.handleChange_payment} required/>
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridState">
                              <Form.Label>Billing state</Form.Label>
                              <Form.Control as="select" name="state" value={this.state.payment.state ? this.state.payment.state : '"Choose..."'}
                                onChange={this.handleChange_payment} required>
                                <option value="">Choose...</option>
                              	<option value="AL">Alabama (AL)</option>
                              	<option value="AK">Alaska (AK)</option>
                              	<option value="AZ">Arizona (AZ)</option>
                              	<option value="AR">Arkansas (AR)</option>
                              	<option value="CA">California (CA)</option>
                              	<option value="CO">Colorado (CO)</option>
                              	<option value="CT">Connecticut (CT)</option>
                              	<option value="DE">Delaware (DE)</option>
                              	<option value="DC">District Of Columbia (DC)</option>
                              	<option value="FL">Florida (FL)</option>
                              	<option value="GA">Georgia (GA)</option>
                              	<option value="HI">Hawaii (HI)</option>
                              	<option value="ID">Idaho (ID)</option>
                              	<option value="IL">Illinois (IL)</option>
                              	<option value="IN">Indiana (IN)</option>
                              	<option value="IA">Iowa (IA)</option>
                              	<option value="KS">Kansas (KS)</option>
                              	<option value="KY">Kentucky (KY)</option>
                              	<option value="LA">Louisiana (LA)</option>
                              	<option value="ME">Maine (ME)</option>
                              	<option value="MD">Maryland (MD)</option>
                              	<option value="MA">Massachusetts (MA)</option>
                              	<option value="MI">Michigan (MI)</option>
                              	<option value="MN">Minnesota (MN)</option>
                              	<option value="MS">Mississippi (MS)</option>
                              	<option value="MO">Missouri (MO)</option>
                              	<option value="MT">Montana (MT)</option>
                              	<option value="NE">Nebraska (NE)</option>
                              	<option value="NV">Nevada (NV)</option>
                              	<option value="NH">New Hampshire (NH)</option>
                              	<option value="NJ">New Jersey (NJ)</option>
                              	<option value="NM">New Mexico (NM)</option>
                              	<option value="NY">New York (NY)</option>
                              	<option value="NC">North Carolina (NC)</option>
                              	<option value="ND">North Dakota (ND)</option>
                              	<option value="OH">Ohio (OH)</option>
                              	<option value="OK">Oklahoma (OK)</option>
                              	<option value="OR">Oregon (OR)</option>
                              	<option value="PA">Pennsylvania (PA)</option>
                              	<option value="RI">Rhode Island (RI)</option>
                              	<option value="SC">South Carolina (SC)</option>
                              	<option value="SD">South Dakota (SD)</option>
                              	<option value="TN">Tennessee (TN)</option>
                              	<option value="TX">Texas (TX)</option>
                              	<option value="UT">Utah (UT)</option>
                              	<option value="VT">Vermont (VT)</option>
                              	<option value="VA">Virginia (VA)</option>
                              	<option value="WA">Washington (WA)</option>
                              	<option value="WV">West Virginia (WV)</option>
                              	<option value="WI">Wisconsin (WI)</option>
                              	<option value="WY">Wyoming (WY)</option>
                              </Form.Control>
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridZip">
                              <Form.Label>Billing zip</Form.Label>
                              <Form.Control name="postal_code" defaultValue={this.state.payment.postal_code} onChange={this.handleChange_payment} required/>
                            </Form.Group>
                          </Form.Row>
                        </div>                         
                }            
                        <Button variant="dark" type="submit">Next</Button>       
                      </Form>    
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="3" onClick={() => this.set_activePanel('3')} className="accordion-header"
                    disabled={! (this.state.address.is_valid && this.state.payment.is_valid) ? 'disabled' : ''}>
                      3. Review
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="3">
                    <Card.Body>
                      <div className="font-weight-bold">Shipping address:</div>
                      <div>{this.state.address.full_name}</div>
                      <div>{this.state.address.address_line1}</div>
                      <div>{this.state.address.address_line2 ? this.state.address.address_line2 : ''}</div>
                      <div>{this.state.address.city}, {this.state.address.state} {this.state.address.postal_code}</div>
                      <div className="font-weight-bold mt-2">Payment method:</div>
                      <div>Card number: {card_number_show}</div>
                      <div>Card expiration: {card_exp_show}</div>
                      <div>Card code: {this.state.payment.card_code}</div>
                      <div className="font-weight-bold mt-2">Billing address:</div>
        { this.state.payment.billing_is_shipping && 
                      <div>
                        <div>{this.state.address.address_line1}</div>
                        <div>{this.state.address.address_line2 ? this.state.address.address_line2 : ''}</div>
                        <div>{this.state.address.city}, {this.state.address.state} {this.state.address.postal_code}</div>  
                      </div> 
        }
        { ! this.state.payment.billing_is_shipping && 
                      <div>
                        <div>{this.state.payment.address_line1}</div>
                        <div>{this.state.payment.address_line2 ? this.state.payment.address_line2 : ''}</div>
                        <div>{this.state.payment.city}, {this.state.payment.state} {this.state.payment.postal_code}</div>
                      </div> 
        }
                      <Button variant="dark" className="mt-2" onClick={this.place_order}>Place Order</Button>       
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </Col>
            <Col sm={12} md={4}>
              <h5>Order Detail and Summary</h5>
              <Table striped hover size="sm">
        { AppState.cart && AppState.cart.items.map(function(item, idx) {
              var theJsx = [];
              theJsx.push(
                <tr key={item.id}>
                  <td>
                    <img className="img-thumbnail" src={assets_url_prefix + item.sku.product_id + '_' + 
                    item.sku.attribute.color + '_thumb0.jpg'} />
                  </td>
                  <td>
                    <div>{item.product_name}</div>
                    <div>{item.sku.description} - {item.sku.attribute.size}</div>
                  </td>
                  <td>
                    <div>{item.quantity}</div>
                  </td>
                  <td>
                    <div>${item.unit_price}</div>
                  </td>
                </tr>
              );
              return (<tbody>{theJsx}</tbody>);
        })}
              </Table> 
        { AppState.cart && 
              <Table striped hover size="sm">
                <tr>
                  <td>Subtotal</td><td className="text-right">${AppState.cart.subtotal}</td>
                </tr>
                <tr>
                  <td>Shipping</td><td className="text-right">{AppState.cart.shipping}</td>
                </tr>
                <tr>
                  <td className="font-weight-bold">Total</td><td className="text-right font-weight-bold">${AppState.cart.total}</td>
                </tr>      
              </Table>
        }
            </Col>
          </Row>
			  </div>
        <Footer />
      </div>
	  );
	}
};

export default view(Checkout);