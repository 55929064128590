import {SendRequest} from 'rtcmesh-react';
import AppState from './AppState';
import { SendRequestAsync } from './SendRequestAsync';

// TODO: use localStorage.removeItem('cart_id'); after converting the cart to an order
//       use localStorage.setItem('cart_id', 'Tom'); to set the cart id

// Cart functions to support the product and cart pages.
export {
  init_cart,
  add_item_to_cart,
  decrease_qty,
  increase_qty,
  cart_local_reset,
};

// Load the cart if there is one, otherwise set the cart object to null. We initialize the cart in App.js
// const init_cart = function(){
//   if(process.env.REACT_APP_TRACE_FN_SEQ) console.log('=*= cart init_cart');
//   // Look for a cart ID in local storage.  Retrieve it if found, else set cart to null.
//   const cart_id = localStorage.getItem('cart_id');
//   if(cart_id){
//     let params = {id : cart_id};
//     SendRequest('retrieve', null, 'cart', params, init_cart_response);
//      AppState.callbacksByTransId['retrieve_cart'] = init_cart_response;
//   }else{
//     AppState.cart = null;
//   }
// }
// const init_cart_response = function(response){
//   if(process.env.REACT_APP_TRACE_FN_SEQ) console.log('=*= cart init_cart_response');
//   console.debug('cart cart callbacksByTransId', AppState.callbacksByTransId)
//   console.log('init_cart_response response', response)
//   if(response.code === 200){
//     if(response.data && response.data.cart) AppState.cart = response.data.cart.data;
//   }
// }
  
const init_cart = async function(){
  if(process.env.REACT_APP_TRACE_FN_SEQ) console.log('=*= cart init_cart');
  // Look for a cart ID in local storage.  Retrieve it if found, else set cart to null.
  const cart_id = localStorage.getItem('cart_id');
  if(cart_id && !AppState.cart){
    let params = {id : cart_id};
    const response =  await SendRequestAsync('retrieve', null, 'cart', params);
    console.log('init_cart response', response)
    if(response.code === 200){
      if(response.data && response.data.cart) AppState.cart = response.data.cart.data;
    }
  }else{
    // 
    if(AppState.cart && AppState.cart.length === 0) AppState.cart = null;
  }
}

const add_item_to_cart = function(sku, quantity, product_name, product_price, callback){
  if(process.env.REACT_APP_TRACE_FN_SEQ) console.log('=*= cart add_item_to_cart');
  let cart_id = null;
  let item = {
    sku: sku,
    quantity: quantity,
    product_name: product_name,
    unit_price: product_price
  }
  if(AppState.cart){
    // TODO: if sku is already in cart then just add quantity
    cart_id = AppState.cart.id
    AppState.cart.items.push(item);
    AppState.cart.updated = new Date().toISOString();
  }else{
    cart_id = Math.uuid();  // Loaded in /public
    AppState.cart = {
      id : cart_id,
      items : [item],
      status : "initiated",
      created : new Date().toISOString(),
      updated : new Date().toISOString()
    }
  }
  set_totals();
  console.log('add_item_to_cart cart_id', cart_id)
  console.log('add_item_to_cart cart', AppState.cart)
  SendRequest('crt_updt', null, 'cart', {id : cart_id, data : AppState.cart},function(response){
    if(response.code === 200){
      console.log('add_item_to_cart response', response);
      // Save the cart id in local storage.
      localStorage.setItem('cart_id', cart_id);
      callback(cart_id);
    }
  });
}

const decrease_qty = function(idx){
  if(--AppState.cart.items[idx].quantity < 1){
    AppState.cart.items.splice(idx,1);
  }
  set_totals();
  save_cart();
}
const increase_qty = function(idx){
  AppState.cart.items[idx].quantity++;
  set_totals();
  save_cart();
}

const cart_local_reset = function(){
  localStorage.removeItem('cart_id');
  AppState.cart = null;
}

// ======================================
// Not exported module support functions.
// ======================================

function save_cart(){
  if(AppState.cart){
    SendRequest('crt_updt', null, 'cart', {id : AppState.cart.id, data : AppState.cart},function(response){
      if(response.code === 200){
        console.log('save_cart response', response);
      }
    });
  }
}

function set_totals(){
  let subtotal = 0;
  for(var i = 0, len = AppState.cart.items.length; i < len; i++){
    subtotal += parseInt(AppState.cart.items[i].unit_price) * parseInt(AppState.cart.items[i].quantity);
  }
  AppState.cart.subtotal = subtotal;
  AppState.cart.shipping = 'Free';
  AppState.cart.total = subtotal;
}