import React from 'react'
import logo from '../../logo.png';
import { view } from 'react-easy-state';
import { Row, Col } from 'react-bootstrap';
import UserNav from './UserNav';
import SiteNav from './SiteNav';
import AlertBanner from './AlertBanner';
import AppState from '../../support/AppState';

class Header extends React.Component {
  
  goHome = () => {
    // const {history} = AppState;   // history set in SiteNav
    // history.push('/');
    window.location = "https://www.theclothmask.com/";
  }
  
  goCart = () => {
    const {history} = AppState;   // history set in SiteNav
    history.push('/cart');
  }  
  
  render() {
    return (
      <div>
        <header className="App-header">
          <Row>
            <Col xs={3} sm={4} md={6} lg={7} >
              <SiteNav/>
            </Col>
            <Col xs={2} sm={2} md={2} lg={2} >
              &nbsp;
            </Col>
            <Col xs={4} sm={4} md={2} lg={2}>
              <UserNav/>
            </Col>
            <Col className="p-3" xs={3} sm={2} md={2} lg={1}>
              <div id="cart-container" onClick={this.goCart} className="cursor_pointer">
                <div id="cart-text">{AppState.cart ? AppState.cart.items.length : '0'}</div>
                <svg id="cart-image" width="1.8em" height="1.8em" viewBox="0 0 16 16" className="bi bi-cart" fill="white" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l1.313 7h8.17l1.313-7H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm7 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
                </svg>
              </div>
            </Col>
          </Row>
        </header>
        <Row>
          <Col>
            <div className="alert-banner">
              <AlertBanner/>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

export default view(Header);
