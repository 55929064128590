import React from "react";
import Header from "../partials/Header";
import Footer from "../partials/Footer";

class Customers extends React.Component {
  
	render() {
		console.log('Home - render');
	  return (
      <div>
        <Header />
			  <div className="content">
          Customers
			  </div>
        <Footer />
      </div>
	  );
	}
};

export default Customers;