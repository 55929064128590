import React from 'react';
import { Form, FormGroup, Button } from 'reactstrap';
import { view } from 'react-easy-state';
import { genFormPlain } from './genFormPlain';
import GenFormState from './GenFormState';
import { genFormParts_FieldGroupArray } from './genFormParts';
import { processSubmit } from './processSubmit';

class GenForm extends React.Component {
  
  constructor(props) {  
    super(props);
    this.state = { formJSX: <div>loading...</div> };
    const { set_prop } = GenFormState;
    set_prop("jsonb_schema_form_layout", props.jsonb_schema_form_layout);
    set_prop('return_form_data', props.return_form_data);
    set_prop('cancel_btn_action', props.cancel_btn_action);
    set_prop("formData", props.formData);
    set_prop("handleChange", this.handleChange);
  }
  
  componentDidMount(){
    const { set_prop } = GenFormState;
    set_prop("jsonb_schema_form_layout", this.props.jsonb_schema_form_layout);
    this.setRemoveWhenRules(this.props.jsonb_schema_form_layout);
    if(this.props.formData){
      this.initDisplayState(this.props.jsonb_schema_form_layout, this.props.formData);
    }else{
      this.resetDisplayState(this.props.jsonb_schema_form_layout);
    }
    this.createFormJSX();
  }
  
  // Initialize the display_state hiding fields depending on existing formData values
  initDisplayState = (jsonb_schema_form_layout, formData) => {
    console.log('GenForm initDisplayState')
    let display_state = {}
    for(let target in jsonb_schema_form_layout.properties){
      if(jsonb_schema_form_layout.properties[target].remove_when){
        let field_id = jsonb_schema_form_layout.properties[target].remove_when.field;
        let field_value = jsonb_schema_form_layout.properties[target].remove_when.value;
        console.log('GenForm initDisplayState target', target, ' - field_id', field_id, ' - field_value', field_value)
        // TODO: look at op other than ===
        if(jsonb_schema_form_layout.properties[target].remove_when.op === '==='){
          if(formData[field_id] === field_value){
            display_state[target] = 'd-none';
          }
        }
      }else{
        display_state[target] = 'd-block';
      }
    }
    const { set_prop } = GenFormState;
    set_prop("display_state", display_state);    
  }
  
  // Show all fields.
  resetDisplayState = (jsonb_schema_form_layout) => {
    let display_state = {}
    for(let target in jsonb_schema_form_layout.properties){
      display_state[target] = 'd-block';
    }
    const { set_prop } = GenFormState;
    set_prop("display_state", display_state);
  }
  
  setRemoveWhenRules = (jsonb_schema_form_layout) => {
    console.log('GenForm setRemoveWhenRules jsonb_schema_form_layout', jsonb_schema_form_layout)
    // Build rules array by source
    let remove_when_rules = {}
    for(let target in jsonb_schema_form_layout.properties){
      console.log('GenForm setRemoveWhenRules target', target)
      if(jsonb_schema_form_layout.properties[target].remove_when){
        let remove_when = jsonb_schema_form_layout.properties[target].remove_when;
        if(remove_when_rules[remove_when.field]){
          if(remove_when_rules[remove_when.field][remove_when.op]){
            if(remove_when_rules[remove_when.field][remove_when.op][remove_when.value]){
              remove_when_rules[remove_when.field][remove_when.op][remove_when.value].push(target);
            }else{
              remove_when_rules[remove_when.field][remove_when.op][remove_when.value] = [];
              remove_when_rules[remove_when.field][remove_when.op][remove_when.value].push(target);
            }
          }else{
            remove_when_rules[remove_when.field][remove_when.op] = {};
            if(remove_when_rules[remove_when.field][remove_when.op][remove_when.value]){
              remove_when_rules[remove_when.field][remove_when.op][remove_when.value].push(target);
            }else{
              remove_when_rules[remove_when.field][remove_when.op][remove_when.value] = [];
              remove_when_rules[remove_when.field][remove_when.op][remove_when.value].push(target);
            }
          }
        }else{
          remove_when_rules[remove_when.field] = {};
          remove_when_rules[remove_when.field][remove_when.op] = {};
          remove_when_rules[remove_when.field][remove_when.op][remove_when.value] = [];
          remove_when_rules[remove_when.field][remove_when.op][remove_when.value].push(target);
        }
      }
    }
    const { set_prop } = GenFormState;
    set_prop("remove_when_rules", remove_when_rules);
  }
  
  handleChange = (event) => {
  	if(event.target.type === 'checkbox'){
  		GenFormState.formData[event.target.id] = event.target.checked;			// updating value
  	}else{
  		GenFormState.formData[event.target.id] = event.target.value;				// updating value
  	}
  }
  
  handleSubmit = (event) => {
    event.preventDefault();
    let form_data = processSubmit(event);
    console.log('GenForm handleSubmit form_data', form_data)
    // Reset the form by resetting the items in GenFormState
    GenFormState.formJsxChunks = [];
    GenFormState.fieldGroupArrayInstaces = {};
    // Return the data
    const { return_form_data } = GenFormState;
    return_form_data(form_data);
  }
  
  handleCancel = () => {
    const { cancel_btn_action } = GenFormState;
    cancel_btn_action();
  }
  
  run_remove_when_rules = (event) => {
    console.log('GenForm run_remove_when_rules', event.target.id)
    const { remove_when_rules, jsonb_schema_form_layout} = GenFormState;
    if(remove_when_rules[event.target.id]){
      console.log('GenForm run_remove_when_rules RUNNING RULES')
      // Show all fields
      this.resetDisplayState(jsonb_schema_form_layout);
      // Hide based on rules.     
      const { display_state } = GenFormState;
      for(let op in remove_when_rules[event.target.id]){
        console.log('GenForm run_remove_when_rules op', op)
        for(let value in remove_when_rules[event.target.id][op]){
          console.log('GenForm run_remove_when_rules value', value)
          // NOTE: how about checkboxes?
          if(event.target.value === value){
            for(var i = 0, len = remove_when_rules[event.target.id][op][value].length; i < len; i++){
              let field_id = remove_when_rules[event.target.id][op][value][i];
              console.log('GenForm run_remove_when_rules field_id', field_id)
              display_state[field_id] = "d-none";
            }
          }
        }
      }
      console.log('GenForm run_remove_when_rules display_state', display_state)
      this.createFormJSX();
    }
  }
  
  createFormJSX = () => {
    const { jsonb_schema_form_layout } = GenFormState;
    console.log('=== GenForm createFormJSX jsonbSchema_form_layout', jsonb_schema_form_layout)
    var form_type = jsonb_schema_form_layout ? jsonb_schema_form_layout.form_type : null;
    console.log('=== GenForm createFormJSX jsonbSchema form_type', form_type)
    if(! form_type) return;
    switch(form_type){
    case 'plain':
      genFormPlain(this);
      break;
    default:
      // TODO: Need to fix this
      // this.setState ({formJSX : <div>ERROR: Unknown form type {form_type}</div>});
    }
  }
  
  add = (item) => {
    genFormParts_FieldGroupArray(item, this);
  }

  render() {
    // Traverse the keyed form chunks arrays and build the formJSX 
    let formJSX = [];
    for(var i = 0, len = GenFormState.formJsxChunks.length; i < len; i++){
      for(let key in GenFormState.formJsxChunks[i]){
        console.log('GenFormState.formJsxChunks[i]', GenFormState.formJsxChunks[i])
        console.log('GenFormState.formJsxChunks[i][key]', GenFormState.formJsxChunks[i][key])
        formJSX.push(GenFormState.formJsxChunks[i][key]);
      }
    }
    // Add the submit button.
    formJSX.push(<FormGroup key="sunmit"> 
      <Button color="primary">Submit</Button> <Button color="secondary" onClick={ this.handleCancel }>Cancel</Button> 
    </FormGroup>);
    console.log('GenFormState.formJSX', formJSX)
    return (
      <Form onSubmit={ this.handleSubmit } key="form">{formJSX}</Form>
    );
  }
}
export default view(GenForm);