import React from 'react';
import { Row, Col, Form, FormGroup, Label, Input, Button, FormText } from 'reactstrap';
import GenFormState from './GenFormState';

export {
  genFormParts_FormGroup,
  genFormParts_FieldGroup,
  genFormParts_FieldGroupArray
};

// FormGroup
const genFormParts_FormGroup = function (field_info) {  //, property_info) {
  console.log('field_info', field_info)
  // console.log('property_info', property_info)
  // console.log('property_info[field_info.field.name]', property_info[field_info.field.name])
  // console.log('field_info.name', field_info.field.name)
  // console.log('property_info[field_info.field.name].input', property_info[field_info.field.name].input)
  switch(field_info.input){
  case 'text':
  case 'textarea':
  case 'number':
    return get_FormGroup_text(field_info); break;
  case 'checkbox': 
    return get_FormGroup_checkbox(field_info); break;
  case 'select': 
    return get_FormGroup_select(field_info); break;
  default:
    console.error('Unhandled input', field_info.input);
    return null;
  }
}

// NOTE: in order to prevent "An invalid form control with name='' is not focusable" we set required to false if display none
function get_FormGroup_text(field_info){
  const { handleChange, formData, display_state } = GenFormState;
  const fieldname = field_info.name;
  return (
    <FormGroup key={fieldname} className={display_state[fieldname]}>
      {field_info.label && <Label for={fieldname}>{field_info.label}</Label>}
      <Input type={field_info.input} name={fieldname} id={fieldname} defaultValue={formData[fieldname]} placeholder="" 
      onChange={handleChange} required={display_state[fieldname] === "d-none" ? false : field_info.required} />
      <FormText>{field_info.help_text}&nbsp;</FormText>
    </FormGroup>
  );
}

function get_FormGroup_checkbox(field_info){  //, property_info){
  const { handleChange, formData, display_state } = GenFormState;
  const fieldname = field_info.name;
  return (
    <FormGroup check key={fieldname} className={display_state[fieldname]}>
      <Label check>
        <Input type="checkbox" id={fieldname} onChange={handleChange} defaultChecked={formData[fieldname]}  />{' '}
        {field_info.label}
      </Label>
    </FormGroup>
  );
}

// NOTE: in order to prevent "An invalid form control with name='' is not focusable" we set required to false if display none
function get_FormGroup_select(field_info){  //, property_info){
  const { handleChange, formData, display_state } = GenFormState;
  const fieldname = field_info.name;
  var optionsJsx = [];
  if(field_info.source === 'select_list'){
    for(var i = 0, len = field_info.options.length; i < len; i++){
      optionsJsx.push(
        <option value={field_info.options[i].value}>{field_info.options[i].label}</option>
      )
    }
  }
  var fieldJsx = [];
  fieldJsx.push(
    <FormGroup key={fieldname} className={display_state[fieldname]}>
      {field_info.label && <Label for={fieldname}>{field_info.label}</Label>}
      <Input type={field_info.input} name={fieldname} id={fieldname} defaultValue={formData[fieldname]} placeholder=""
      onChange={handleChange} required={display_state[fieldname] === "d-none" ? false : field_info.required}>{optionsJsx}</Input>
      <FormText>{field_info.help_text}&nbsp;</FormText>
    </FormGroup>
  );
  return fieldJsx;
}

// fieldGroup
// NOTE: in order to prevent "An invalid form control with name='' is not focusable" we set required to false if display none
const genFormParts_FieldGroup = function (item){  //, properties) {
  const { handleChange, formData, display_state } = GenFormState;
  const first_fieldname = item.fieldGroup.fields[0].field.name;
  if(item.fieldGroup.fields[0].field.input === 'checkbox'){
    // TODO: implement checkboxes.
    return null;
  }else{
    var inputsJsx = [];
    for(var k = 0, klen = item.fieldGroup.fields.length; k < klen; k++){
      var field_info = item.fieldGroup.fields[k];
      var input = (
        <FormGroup>
          <Input type={field_info.input} name={field_info.field.name} id={field_info.field.name} 
          placeholder={field_info.field.label} required={display_state[first_fieldname] === "d-none" ? false : field_info.required} key={field_info.field.name + k} onChange={handleChange} 
          defaultValue={formData[field_info.field.name]} />
          <FormText>{field_info.field.help_text}&nbsp;</FormText>
        </FormGroup>
      );
      inputsJsx.push(input);
      if(k + 1 < klen) inputsJsx.push(<span key={'span' + field_info.field.name + k}>&nbsp;</span>);
    }
    return (
      <div key={Math.uuid()} className={display_state[first_fieldname]}>
        <div>{item.fieldGroup.label}</div>
        <FormGroup check inline>
          {inputsJsx}
        </FormGroup>
      </div>
    );
  }
}

// array of fieldGroup
// NOTE: in order to prevent "An invalid form control with name='' is not focusable" we set required to false if display none
const genFormParts_FieldGroupArray = function (item, _this){
  const { handleChange, formData, display_state } = GenFormState;
  const first_fieldname = item.fieldGroup.fields[0].field.name;
  if(item.fieldGroup.fields[0].field.input === 'checkbox'){
    // TODO: implement checkboxes.
    return null;
  }else{
    // Set the index of the current fieldGroup array and initialize fieldGroupArrayInstaces if this is the first one.
    let idx = 0;
    if(! GenFormState.fieldGroupArrayInstaces[item.name]){
      GenFormState.fieldGroupArrayInstaces[item.name] = [];
    }else{
      // Set the next index.
      idx = GenFormState.fieldGroupArrayInstaces[item.name].length;
    }
    console.log('genFormParts_FieldGroupArray idx', idx)
    let fld_prefix = item.name + '[' + idx + '].';
    let inputsJsx = [];
    for(var k = 0, klen = item.fieldGroup.fields.length; k < klen; k++){
      let field_info = item.fieldGroup.fields[k];
      let field_name = fld_prefix + field_info.field.name;
      let input = (
        <FormGroup>
          <Input type={field_info.input} name={field_name} id={field_name} 
          placeholder={field_info.field.label} required={display_state[first_fieldname] === "d-none" ? false : field_info.required} key={field_info.field.name + k} onChange={handleChange} 
          defaultValue={formData[field_name]} />
          <FormText>{field_info.field.help_text}&nbsp;</FormText>
        </FormGroup>
      );
      inputsJsx.push(input);
      if(k + 1 < klen) inputsJsx.push(<span key={'span' + field_info.field.name + k}>&nbsp;</span>);
    }
    // Save inputsJsx adding the FormGroup to the fieldGroupArrayInstaces
    let form_group_chunk = (
      <FormGroup check inline>
        {inputsJsx}
      </FormGroup>   
    ); 
    GenFormState.fieldGroupArrayInstaces[item.name][idx] = form_group_chunk;
    // Get all the form_group_chunks
    let form_group_chunks = []
    for(var i = 0, len = GenFormState.fieldGroupArrayInstaces[item.name].length; i < len; i++){
      form_group_chunks.push(GenFormState.fieldGroupArrayInstaces[item.name][i]);
      console.log('genFormParts_FieldGroupArray form_group_chunks i', i)
    }
    // Set form_group_chunks into the GenFormState.formJsxChunks
    let chunk = (
      <div key={Math.uuid()} className={display_state[first_fieldname]}>
        <div>{item.fieldGroup.label}</div>
        {form_group_chunks}
        <div className="mb-2"><Button size="sm" color="info" onClick={() => _this.add(item)}>Add Attribute</Button></div>
      </div>
    );
    if(idx === 0){
      GenFormState.formJsxChunks.push({[item.name] : chunk});
    }else{
      // Find the item.name chunk and replace it with the new chunk.
      for(var i = 0, len = GenFormState.formJsxChunks.length; i < len; i++){
        let the_chunk = GenFormState.formJsxChunks[i];
        if(the_chunk[item.name]){
          GenFormState.formJsxChunks[i] = {[item.name] : chunk}
          break;
        }
      }
    }
  }
}
