import { store } from 'react-easy-state'

const GenFormState = store({
  handleChangeForward: null,
  handleChange: null,
  jsonb_schema_form_layout: null,
  formData: null,
  display_state: {},
  remove_when_rules: null,
  formJsxChunks: [],
  fieldGroupArrayInstaces: {},
  set_prop(key, val){
    console.log('GenFormState - setting', key, '=', val)
    GenFormState[key] = val;
  },
})

export default GenFormState