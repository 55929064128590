import React from "react";
import { view } from 'react-easy-state';
import {SendRequest} from 'rtcmesh-react';
import { Accordion, Card, Button, Form, Row, Col, Table, Modal } from 'react-bootstrap'
import Header from "../partials/Header";
import Footer from "../partials/Footer";
import AppState from '../../support/AppState';
import { order_get_order, order_set_processed } from '../../support/order';


class ProcessOrder extends React.Component {
  
  constructor(props) {
    if(process.env.REACT_APP_TRACE_FN_SEQ) console.log('=*= ProcessOrder constructor');
    super(props);
    this.state = { order: null, activePanel : '1', current_step : 1, modalIsOpen: false }
    AppState.order = null;
  }
  
  componentDidMount(){
    if(process.env.REACT_APP_TRACE_FN_SEQ) console.log('=*= ProcessOrder componentDidMount');
    order_get_order(this.props.match.params.id, function(){});
  }

  set_activePanel = (eventKey) => {
    if(process.env.REACT_APP_TRACE_FN_SEQ) console.log('=*= ProcessOrder set_activePanel');
    this.setState({activePanel : eventKey, current_step : parseInt(eventKey)});
  }
  
  print_shipping_label = () => {
    if(process.env.REACT_APP_TRACE_FN_SEQ) console.log('=*= ProcessOrder print_shipping_label');
    // alert('Use the manual entry link')
    window.open('https://app.goshippo.com/orders/manual', 'newwindow', 'width=1024,height=765');
    return false;
  }
  
  done_processing = () => {
    if(process.env.REACT_APP_TRACE_FN_SEQ) console.log('=*= ProcessOrder done_processing');
    // Open the wait modal
    const { assets_url_prefix } = AppState;
    let _this = this, theJsx = (<div className="text-center"><img src={assets_url_prefix + 'wait.webp'} /></div>)
    this.setState({ modal_title :'Updating order status', modal_content_jsx : theJsx, modalIsOpen: true });
    // Capture the transaction and update the status to processed.
    order_set_processed(AppState.order.id, function(response){
      console.debug('ProcessOrder done_processing order_set_processed response', response);
      if(response.code === 200){
      // Go to the Fulfillment page
      _this.props.history.push('/fulfillment')
      }else{
        const { showAlertBanner } = AppState;
        if(showAlertBanner) showAlertBanner('danger', response.message, 8000);
      }
    });
  }
  
	render() {
		if(process.env.REACT_APP_TRACE_FN_SEQ) console.log('=*= ProcessOrder render');
    console.debug('ProcessOrder render current_step', this.state.current_step)
	  return (
      <div>
        <Header />
			  <div className="content">
          <Modal show={this.state.modalIsOpen} backdrop="static" onHide={this.hideModal}>
            <Modal.Header closeButton>
              <Modal.Title>{this.state.modal_title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.state.modal_content_jsx}
            </Modal.Body>
          </Modal>
          <h3>Process Order</h3>
          <Row className="mt-2">
            <Col sm={12} md={8}>
              <Accordion activeKey={this.state.activePanel}>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="1" onClick={() => this.set_activePanel('1')} className="accordion-header">
                      <h5>1. Place the items below in the shipping envelope.</h5>
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="1">
                    <Card.Body>
                      <Table striped bordered hover size="sm">
                        <thead>
                          <tr>
                            <th>&nbsp;</th>
                            <th>Qty</th>
                            <th>SKU</th>
                            <th>Color</th>
                            <th>Size</th>
                          </tr>
                        </thead>
                  {! AppState.order && <tr><td>Loading...</td></tr>}
                  {AppState.order && AppState.order.items.map(function(item, idx){
                        var theJsx = [];
                        theJsx.push(
                          <tr key={'sku' + item.sku.id}>
                            <td><Form.Check type="checkbox" /></td>
                            <td>{item.quantity}</td>
                            <td>{item.sku.id}</td>
                            <td>{item.sku.attribute.color}</td>
                            <td>{item.sku.attribute.size}</td>
                          </tr>
                        );
                        return (<tbody key={'sku_tbody' + item.sku.id}>{theJsx}</tbody>);
                  })} 
                      </Table> 
                      <Button variant="dark" className="mt-2" onClick={() => this.set_activePanel('2')}>Done</Button> 
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>  
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="2" onClick={() => this.set_activePanel('2')} className="accordion-header"
                    disabled={this.state.current_step < 2 ? 'disabled' : ''}>
                      <h5>2. Create & print the shipping label.</h5>
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="2">
                    <Card.Body> 
                  {! AppState.order && <div>Loading...</div>}
                  {AppState.order && 
                      <div>
                        <div>{AppState.order.address.full_name}</div>
                        <div>{AppState.order.payment.customer_email}</div>
                        <div>{AppState.order.address.Address1}</div>
                        <div>{AppState.order.address.Address2}</div>
                        <div className="mb-2">
                          {AppState.order.address.City}, {AppState.order.address.State} &nbsp;
                          {AppState.order.address.Zip5}-{AppState.order.address.Zip4}
                        </div>
                        <Button variant="dark" className="mr-2" onClick={this.print_shipping_label}>
                          Create & Print the Shipping Label
                        </Button>    
                        <Button variant="dark" onClick={() => this.set_activePanel('3')}>Done</Button> 
                      </div>
                  }
                    </Card.Body>
                  </Accordion.Collapse>
                </Card> 
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="3" onClick={() => this.set_activePanel('3')} className="accordion-header"
                    disabled={this.state.current_step < 3 ? 'disabled' : ''}>
                      <h5>3. Place the label on the shipping envelope.</h5>
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="3">
                    <Card.Body>      
                      <Button variant="dark" className="mt-2" onClick={() => this.set_activePanel('4')}>Done</Button> 
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>       
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="4" onClick={() => this.set_activePanel('4')} className="accordion-header"
                    disabled={this.state.current_step < 4 ? 'disabled' : ''}>
                      <h5>4. Put the shipping envelope in the outgoing bin.</h5>
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="4">
                    <Card.Body>
                      <Button variant="dark" className="mt-2" onClick={this.done_processing}>Done Processing</Button> 
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </Col>
          </Row>
			  </div>
        <Footer />
      </div>
	  );
	}
};

export default view(ProcessOrder);