import React from "react";
import Header from "../partials/Header";
import Footer from "../partials/Footer";
import AppState from '../../support/AppState';
import { Table, Row, Col, Button, Modal, Form } from 'react-bootstrap'
import {SendRequest} from 'rtcmesh-react';
import { SendRequestAsync } from '../../support/SendRequestAsync';
import { view } from 'react-easy-state';
// import { GenForm, genFormReset } from 'react-bootstrap-formgen';
import { GenForm, genFormReset } from '../../support/bootstrap-formgen';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

class Inventory extends React.Component {
  constructor(props) {
    if(process.env.REACT_APP_TRACE_FN_SEQ) console.debug('=*= Inventory constructor');
    super(props);
    this.state = { formData: {}, modalIsOpen: false, products: null, curr_product_id : '', skus : [] }
  }
  
  async componentDidMount(){
    if(process.env.REACT_APP_TRACE_FN_SEQ) console.debug('=*= Inventory componentDidMount');
    try{
      const { jsonb_schema_sku_form_layout } = AppState;
      console.log('jsonb_schema_sku_form_layout', jsonb_schema_sku_form_layout)
      if(!jsonb_schema_sku_form_layout) await this.load_jsonb_schema_sku_form_layout();
      //
      await this.load_products();
    } catch (error) {
      console.error(error);
      const { showAlertBanner } = AppState;
      if(showAlertBanner) showAlertBanner('danger', "An error occurred loading the products", 8000);
    }
  }
  
  load_jsonb_schema_sku_form_layout = async () => {
    if(process.env.REACT_APP_TRACE_FN_SEQ) console.debug('=*= Inventory load_jsonb_schema_sku_form_layout');
    const response =  await SendRequestAsync('retrieve', null, 'jsonb_schema_form_layout', {schema_name : 'skus'});
    const { set_prop } = AppState;
    if(response.code === 200){
      set_prop('jsonb_schema_sku_form_layout', response.data);
    }    
  }
  
  load_products = async () => {
    if(process.env.REACT_APP_TRACE_FN_SEQ) console.debug('=*= Inventory load_products');
    const response =  await SendRequestAsync('retrieve', null, 'products', null);
    console.log('Inventory load_products response', response)
    if(response.code === 200){
      this.setState({products : response.data.products});
      if(response.data.products && response.data.products[0]) this.show_skus(null, response.data.products[0].id);
    }
  }
  
  save_form_data = (form_data) => {
    if(process.env.REACT_APP_TRACE_FN_SEQ) console.debug('=*= Inventory save_form_data');
    console.log('Catalog save_form_data form_data', form_data)
    // TODO: Validate the data against the product to make sure that 
    //       the atrributes names and values in the SKU exist in the product.
    this.hideModal();
    form_data.json_data.product_id = this.state.curr_product_id
    let _this = this;
    SendRequest('crt_updt', null, 'sku', {id : form_data.id, data : form_data},function(response){
      if(response.code === 200){
        console.log('Catalog handleSubmit response', response);
        _this.setState({ formData: {} });
        _this.load_skus(_this.state.curr_product_id);
      }
    });
  }
  
  load_skus = (product_id) => {
    if(process.env.REACT_APP_TRACE_FN_SEQ) console.debug('=*= Inventory load_skus');
    let _this = this;
    const params = {filter : "data->'data'->'json_data'->>'product_id' = '" + product_id + "'"};
    SendRequest('retrieve', null, 'skus', params, function(response){
      console.log('Inventory load_skus response', response)
      if(response.code === 200){
        let skus = response.data.skus;
        // Sort by color.
        skus.sort(function(a, b) {
          return a.data.json_data.attribute[1].value.localeCompare(b.data.json_data.attribute[1].value);
        });
        _this.setState({skus : skus});
      }
    });
  }
  
  show_skus = (event, prod_id) => {
    if(process.env.REACT_APP_TRACE_FN_SEQ) console.debug('=*= Inventory show_skus');
    console.log('Inventory show_skus id', prod_id, '- target', (event ? event.target.value : null))
    let product_id = prod_id ? prod_id : event.target.value;
    console.log('Inventory show_skus product_id', prod_id)
    this.setState({curr_product_id : product_id});
    this.load_skus(product_id);
  }
  
  showModal = () => {
    if(process.env.REACT_APP_TRACE_FN_SEQ) console.debug('=*= Inventory showModal');
    this.setState({ modalIsOpen: true });
  };

  hideModal = () => {
    if(process.env.REACT_APP_TRACE_FN_SEQ) console.debug('=*= Inventory hideModal');
    this.setState({ modalIsOpen: false });
    this.setState({formData : {}});
    genFormReset();
  };
  
  add_sku = () => {
    if(process.env.REACT_APP_TRACE_FN_SEQ) console.debug('=*= Inventory add_sku');
    // Initialize the attributes.
    let formData = {
      ['attributes[0].name'] : "size",
      ['attributes[0].value']: "",
      ['attributes[1].name']: "color",
      ['attributes[1].value']: ""
    }
    console.log('add_sku formData', formData)
    this.setState({formData : formData});
    this.showModal()
  }
  
  edit_sku = (id) => {
    if(process.env.REACT_APP_TRACE_FN_SEQ) console.debug('=*= Inventory edit_sku');
    console.log('edit_sku id', id)
    // Set the data and show the form
    for(var i = 0, len = this.state.skus.length; i < len; i++){
      console.log('edit_sku sku', this.state.skus[i])
      console.log(this.state.skus[i].id, '-', id)
      if(this.state.skus[i].data.id == id){
        console.log('edit_sku formData', this.state.skus[i].data.formData)
        let formData = this.state.skus[i].data.formData;
        if(! formData.id) formData.id = id;
        console.log('edit_sku formData', formData)
        this.setState({formData : formData});
        this.showModal();
      }
    }
  }
  
  create_labels = (sku_id) => {
    if(process.env.REACT_APP_TRACE_FN_SEQ) console.debug('=*= Inventory create_labels');
    let curr_product = null;
    for(var i = 0, len = this.state.products.length; i < len; i++){
      if(this.state.products[i].id === this.state.curr_product_id){
        curr_product = this.state.products[i].data;
        break;
      }
    }
    if(! curr_product){
      alert("ERROR: can't find product data.");
      return;
    }
    let curr_sku = null;
    for(var i = 0, len = this.state.skus.length; i < len; i++){
      if(this.state.skus[i].data.id === sku_id){
        curr_sku = this.state.skus[i].data;
        break;      
      }
    }
    if(! curr_sku){
      alert("ERROR: can't find sku data.");
      return;
    }    
    // Build the labels. All measurments are in points. 72 points = 1 inch. Default font size 12 points
    let content = [];
    let start_x = 27, incr_x = 147, start_y = [38, 50, 62], incr_y = 36, vert_incr_multiplier = 0, hor_incr_multiplier = 0;
    let strt_row = 0, end_row = 19;  // first row is 0
    for(var i = strt_row * 4, len = end_row * 4 + 4 ; i < len; i++){
      vert_incr_multiplier = i %  4 === 0 ?  i/4 : vert_incr_multiplier;
      hor_incr_multiplier = (i %  4);
      console.log('modulo', hor_incr_multiplier, vert_incr_multiplier, i, i %  4, i/4 );
      console.log('x:', start_x + (incr_x * hor_incr_multiplier), 'y:', start_y[0] + (incr_y * vert_incr_multiplier) )
      content.push(
    		{
    			absolutePosition: { x: start_x + (incr_x * hor_incr_multiplier), y: start_y[0] + (incr_y * vert_incr_multiplier) },
          text: curr_product.json_data.name,
    		}
      );  // Product name
      content.push(
    		{
          absolutePosition: { x: start_x + (incr_x * hor_incr_multiplier), y: start_y[1] + (incr_y * vert_incr_multiplier) },
          text: '#' + sku_id + ' - ' + curr_sku.json_data.attribute[0].value + ', ' + curr_sku.json_data.attribute[1].value,
          fontSize: 10,
    		}
      );  // Size and color   
      content.push(
    		{
          absolutePosition: { x: start_x + (incr_x * hor_incr_multiplier), y: start_y[2] + (incr_y * vert_incr_multiplier) },
          columns: [
            {
              width: 45,
              text: 'Pat. Pend.',
              fontSize: 9 
            },
            {
              width: 55,
              text: 'Made in USA',
              fontSize: 9 
            }
          ],
          columnGap: 10,
    		}
      );  // sku and Made in USA      
    }
    // Set the PDF definition and create the PDF
    var docDefinition = { content: content, pageMargins: [ 0, 0, 0, 0 ], }
    pdfMake.createPdf(docDefinition).open();
  }
  
	render() {
		if(process.env.REACT_APP_TRACE_FN_SEQ) console.debug('=*= Inventory render');
    const { jsonb_schema_sku_form_layout } = AppState;
    console.debug('Inventory - render jsonb_schema_sku_form_layout', jsonb_schema_sku_form_layout)
    console.debug('Inventory - render this.state.skus', this.state.skus)
    const show_skus = this.show_skus, edit_sku = this.edit_sku, create_labels = this.create_labels;
	  return (
      <div>
        <Header />
			  <div className="content">
      {! jsonb_schema_sku_form_layout && ! this.state.products && 
          <div>Loading...</div>
      }
      {this.state.products && 
          <div>
            <Form.Control as="select" onChange={show_skus}>
              {this.state.products && this.state.products.map(function(item, idx){
                return (<option value={item.data.id}>{item.data.json_data.name}</option>)
              })}
            </Form.Control>
            <Form.Text>Select a product to see the SKUs</Form.Text>
          </div>
      }
      {jsonb_schema_sku_form_layout &&
          <div>
            <Button variant="primary" onClick={this.add_sku}>Add SKU</Button>
            <Modal show={this.state.modalIsOpen} backdrop="static" onHide={this.hideModal}>
              <Modal.Header closeButton>
                <Modal.Title>SKU</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <GenForm jsonb_schema_form_layout={jsonb_schema_sku_form_layout} cancel_btn_action={this.hideModal}
                return_form_data={this.save_form_data} formData={this.state.formData}/>
              </Modal.Body>
            </Modal>
          </div>
      }
          <h2>SKUs</h2>
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th>&nbsp;</th>
                 <th>&nbsp;</th>
                <th>Description</th>
                <th>Qty</th>
                <th>Backorder</th>
                <th>Color</th>
                <th>Size</th>
              </tr>
            </thead>
      {jsonb_schema_sku_form_layout && this.state.skus && this.state.skus.map(function(item, idx){
            var theJsx = [];
            theJsx.push(
              <tr key={item.id}>
                <td><Button variant="primary" size="sm" onClick={() => edit_sku(item.data.id)}>Edit</Button></td>
                <td><Button variant="primary" size="sm" onClick={() => create_labels(item.data.id)}>Labels</Button></td>
                <td>{item.data.json_data.description}</td>
                <td>{item.data.json_data.quantity}</td>
                <td>{item.data.json_data.can_backorder ? 'Yes' : 'No'}</td>
                <td>{item.data.json_data.attribute[1].value}</td>
                <td>{item.data.json_data.attribute[0].value}</td>
              </tr>
            );
            return (<tbody>{theJsx}</tbody>);
      })} 
          </Table>      
			  </div>
        <Footer />
      </div>
	  );
	}
};

export default view(Inventory);