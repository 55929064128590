import {SendRequest} from 'rtcmesh-react';

// Order functions to support the checkout and dulfillment pages.
export {
  SendRequestAsync,
};


const SendRequestAsync = (action, service, resource, parameters) => {
  console.debug('** SendRequestAsyn call', action, resource);
  return new Promise((resolve, reject) => {
    SendRequest(action, service, resource, parameters, async (response) => {
      console.debug('** SendRequestAsyn resolve', action, resource, '- response', response);
      resolve(response);
    });
  });
}
