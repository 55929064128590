import React from "react";
import { Table, Row, Col, Button } from 'react-bootstrap'
import { view } from 'react-easy-state';
import Header from "../partials/Header";
import Footer from "../partials/Footer";
import AppState from '../../support/AppState';
import { increase_qty, decrease_qty } from '../../support/cart';

class Cart extends React.Component {
  
  constructor(props) {
    if(process.env.REACT_APP_TRACE_FN_SEQ) console.log('=*= Cart constructor');
    super(props);
    this.state = {}
  }
  
  // componentDidMount(){
  //   if(process.env.REACT_APP_TRACE_FN_SEQ) console.log('=*= Cart componentDidMount');
  // }

  checkout = () => {
    // localStorage.removeItem('cart_id');
    const {history} = AppState;   // history set in SiteNav
    history.push('/checkout');
  }
  
  go_to_product = (product_id, sku_id) => {
    const {history} = AppState;   // history set in SiteNav
    history.push('/product/' + product_id + '?sku_id=' + sku_id);
  }
  
	render() {
		if(process.env.REACT_APP_TRACE_FN_SEQ) console.log('=*= Cart render');
    const { assets_url_prefix } = AppState, go_to_product = this.go_to_product;
    // const decrease_qty = this.decrease_qty, increase_qty = this.increase_qty;
	  return (
      <div>
        <Header />
			  <div className="content">
          <h3>Cart</h3>
      { (!AppState.cart || AppState.cart.items.length == 0) && <div>Your shopping cart is empty</div> }
      { AppState.cart && AppState.cart.items.length > 0 && 
          <Row className="mt-2">
            <Col sm={12} md={9}>
              <Table striped bordered hover size="sm">
        { AppState.cart.items.map(function(item, idx) {
              var theJsx = [];
              theJsx.push(
                <tr key={item.id}>
                  <td>
                    <img className="img-thumbnail cursor_pointer" src={assets_url_prefix + item.sku.product_id + '_' + 
                    item.sku.attribute.color + '_thumb0.jpg'} onClick={() => go_to_product(item.sku.product_id, item.sku.id)}/>
                  </td>
                  <td>
                    <div>{item.product_name} - {item.sku.description}</div>
                    <div>{item.sku.attribute.size}</div>
                    <div>${item.unit_price}</div>
                  </td>
                  <td>
                    <Button variant="primary" size="sm" variant="dark" onClick={() => decrease_qty(idx)}> - </Button>
                    <span className="cart-item_quantity">&nbsp;{item.quantity}&nbsp;</span>
                    <Button variant="primary" size="sm" variant="dark" onClick={() => increase_qty(idx)}> + </Button>
                  </td>
                </tr>
              );
              return (<tbody>{theJsx}</tbody>);
        })}
              </Table> 
            </Col>
            <Col sm={12} md={3}>
              <Button variant="dark" block onClick={this.checkout}>CHECKOUT</Button> 
              <Table bordered size="sm">
                <tr>
                  <td>Subtotal</td><td className="text-right">${AppState.cart.subtotal}</td>
                </tr>
                <tr>
                  <td>Shipping</td><td className="text-right">{AppState.cart.shipping}</td>
                </tr>
                <tr>
                  <td className="font-weight-bold">Total</td><td className="text-right font-weight-bold">${AppState.cart.total}</td>
                </tr>      
              </Table> 
            </Col>
          </Row>
      }
			  </div>
        <Footer />
      </div>
	  );
	}
};

export default view(Cart);