import React from "react";
import { Row, Col, Table } from 'react-bootstrap'
import Header from "../partials/Header";
import Footer from "../partials/Footer";
import AppState from '../../support/AppState';
import { order_get_order } from '../../support/order';

class OrderStatus extends React.Component {
  
  constructor(props) {
    if(process.env.REACT_APP_TRACE_FN_SEQ) console.log('=*= OrderStatus constructor');
    super(props);
    let query = new URLSearchParams(props.location.search);
    let is_new_order = query.get('new') === 'Y' ? true : false;
    this.state = { is_new_order : is_new_order, valid_order : false, loading : true}
  }
  
  componentDidMount(){
    if(process.env.REACT_APP_TRACE_FN_SEQ) console.log('=*= OrderStatus componentDidMount');
    let _this = this;
    order_get_order(this.props.match.params.id, function(){
      console.debug('OrderStatus componentDidMount order', AppState.order);
      if(AppState.order){
        _this.setState({valid_order : true})
      }
      _this.setState({loading : false})
    });
  }
  
	render() {
    if(process.env.REACT_APP_TRACE_FN_SEQ) console.log('=*= OrderStatus render');
    const { user, assets_url_prefix } = AppState;
    let order_date = this.state.valid_order ? new Date(AppState.order.order_date).toLocaleDateString('en-US',{dateStyle:'long'}) : '';
	  return (
      <div>
        <Header />
			  <div className="content">
      { ! this.state.valid_order && ! this.state.loading && 
          <h4>Order not found.</h4>
      }
      { this.state.is_new_order && this.state.valid_order && 
          <div>
            <h3>Thank you for your order.</h3>
            <div>Please check your email for order confirmation.</div>
          </div>
      }
      { ! this.state.is_new_order && this.state.valid_order && 
          <div>
            <h3>Order Details</h3>
            <div>Ordered on {order_date} &nbsp;&nbsp; Order# {AppState.order.id}</div>
            <Row className="mt-3">
              <Col sm={12} md={4}>
                <div className="font-weight-bold mt-2">Shipping address:</div>
                <div>{AppState.order.address.full_name}</div>
                <div>{AppState.order.address.Address1}</div>
                <div>{AppState.order.address.Address2 ? AppState.order.address.Address2 : ''}</div>
                <div>{AppState.order.address.City}, {AppState.order.address.State} {AppState.order.address.Zip5}-{AppState.order.address.Zip4}</div>
              </Col>
              <Col sm={12} md={4}>
                <div className="font-weight-bold mt-2">Payment Method:</div>
                <div>{AppState.order.payment.auth.transactionResponse.accountType} {AppState.order.payment.auth.transactionResponse.accountNumber}</div>
              </Col>
              <Col sm={12} md={4}>
                <div className="font-weight-bold mt-2">Order Summary</div>
                <Table striped hover size="sm">
                  <tr>
                    <td>Subtotal</td><td className="text-right">${AppState.order.subtotal}</td>
                  </tr>
                  <tr>
                    <td>Shipping</td><td className="text-right">{AppState.order.shipping}</td>
                  </tr>
                  <tr>
                    <td className="font-weight-bold">Total</td><td className="text-right font-weight-bold">${AppState.order.total}</td>
                  </tr>      
                </Table>
              </Col>
            </Row>
          </div>
      }
      { this.state.valid_order && 
          <Row className="mt-3">
            <Col sm={12} md={8}>
              <h5>Items</h5>
              <Table striped hover size="sm">
        { AppState.order && AppState.order.items.map(function(item, idx) {
              var theJsx = [];
              theJsx.push(
                <tr key={item.id}>
                  <td>
                    <img className="img-thumbnail" src={assets_url_prefix + item.sku.product_id + '_' + 
                    item.sku.attribute.color + '_thumb0.jpg'} />
                  </td>
                  <td>
                    <div>{item.product_name}</div>
                    <div>{item.sku.description} - {item.sku.attribute.size}</div>
                  </td>
                  <td>
                    <div>{item.quantity}</div>
                  </td>
                  <td>
                    <div>${item.unit_price}</div>
                  </td>
                </tr>
              );
              return (<tbody>{theJsx}</tbody>);
        })}
              </Table>         
            </Col>
            <Col sm={12} md={4}>
              &nbsp;
            </Col>
          </Row>
      }
			  </div>
        <Footer />
      </div>
	  );
	}
};

export default OrderStatus;