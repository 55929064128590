import React, { Component } from 'react';
import './App.css';
import {ServerConnection} from 'rtcmesh-react';
import AppState from './support/AppState';
import Router from './support/Router';

class App extends Component {
  constructor(props) {
    if(process.env.REACT_APP_TRACE_FN_SEQ) console.log('=*= App constructor')
    super(props);
  }
  
  alertCallback = (severity, message) => {
    if(process.env.REACT_APP_TRACE_FN_SEQ) console.log('=*= App alertCallback')
    console.log('alertCallback', severity, message);
    const { showAlertBanner } = AppState;
    if(showAlertBanner) showAlertBanner(severity, message, 3000);
  };
  
  // componentDidMount(){
  //   if(process.env.REACT_APP_TRACE_FN_SEQ) console.log('=*= App componentDidMount')
  // }
  
  render() {
    if(process.env.REACT_APP_TRACE_FN_SEQ) console.log('=*= App render')
    return (
      <div className="App">
        <ServerConnection
          REACT_APP_SERVER_URL={process.env.REACT_APP_SERVER_URL}
          alertCallback={this.alertCallback} />
        <Router/> 
      </div>
    );
  }
}

export default App;
