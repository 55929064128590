import React from "react";
import { Collapse, Navbar, Nav } from 'react-bootstrap'; 
import { Auth } from 'aws-amplify';
import { view } from 'react-easy-state';
import { Link, NavLink } from 'react-router-dom'
import AppState from '../../support/AppState';
import { init_cart } from '../../support/cart';
import { SendRequestAsync } from '../../support/SendRequestAsync';
import { withRouter } from 'react-router-dom';


class SiteNav extends React.Component {

  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
    };
  }
  
  async componentDidMount() {
    console.log('SiteNav componentDidMount');
    const { set_prop } = AppState;
    set_prop("history", this.props.history);
    Auth.currentAuthenticatedUser()
    .then((user) => {
      const { set_prop } = AppState;
      set_prop("user", user);
      set_prop("isAuthenticated", true);
      set_prop("userRole", user.attributes['custom:role']);
      this.setState({isAuthenticated:true});  // Not sure why this is needed.  May be it trigger a refresh
    })
    .catch((err) => console.log('SiteNav componentDidMount catch', err))
    await init_cart();
  }
  
  // Repkace with new lifecycle https://reactjs.org/blog/2018/03/27/update-on-async-rendering.html
	componentWillReceiveProps(props) {
    console.log('SiteNav componentWillReceiveProps props', props);
  }
	
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

	render() {
    const { isAuthenticated, userRole } = AppState; 
    return (
			<Navbar bg="black" variant="dark" expand="lg" id="main-menu" className="top-navbar">
				<Navbar.Toggle onClick={this.toggle} />
      <Navbar.Collapse isOpen={this.state.isOpen} navbar >
				 <Nav>
				   <Nav.Item>
				     <Nav.Link href="https://www.theclothmask.com/">Home</Nav.Link>
				   </Nav.Item>
				   <Nav.Item>
				     <Nav.Link activeClassName="active" as={NavLink} to="/products">Products</Nav.Link>
				   </Nav.Item>
        { isAuthenticated && userRole == 'Admin' &&
           <Nav.Item>
             <Nav.Link activeClassName="active" as={NavLink} to="/catalog">Catalog</Nav.Link>
           </Nav.Item>
        }
        { isAuthenticated && (userRole == 'Inventory' || userRole == 'Admin') &&
           <Nav.Item>
             <Nav.Link activeClassName="active" as={NavLink} to="/inventory">Inventory</Nav.Link>
           </Nav.Item>
        }
        { isAuthenticated && (userRole == 'Fulfillment' || userRole == 'Admin') &&
           <Nav.Item>
             <Nav.Link activeClassName="active" as={NavLink} to="/fulfillment">Fulfillment</Nav.Link>
           </Nav.Item>
        }
        { isAuthenticated && userRole == 'Admin' &&
           <Nav.Item>
             <Nav.Link activeClassName="active" as={NavLink} to="/customers">Customers</Nav.Link>
           </Nav.Item>
        }
				 </Nav>
				</Navbar.Collapse>
			</Navbar>
    )
	}
};

export default view(withRouter(SiteNav));
