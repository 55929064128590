import React from 'react'
import { Auth } from 'aws-amplify';
import { view } from 'react-easy-state';
import { Button, Navbar, Nav, NavDropdown } from 'react-bootstrap';
import AppState from '../../support/AppState';

class UserNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = { dropdownOpen: false };
  }

  signOut = () => {
    const { history, set_prop } = AppState;   // history set in SiteNav
    Auth.signOut()
      .then(() => {
        set_prop("user", null);
        set_prop("isAuthenticated", false);
        history.push('/')
      })
      .catch(() => console.log('error signing out...'))
  }
  signIn = () => {
    const { history } = AppState;   // history set in SiteNav
    history.push('/signin');
  }
  signUp = () => {
    const { history } = AppState;   // history set in SiteNav
    history.push('/signup')
  }
  ChangePassword = () => {
    const { history } = AppState;   // history set in SiteNav
    history.push('/changepassword')
  }
  
  render() {
    const { user, isAuthenticated } = AppState;  // user.attributes.given_name
    return (
      <div>
    {!isAuthenticated &&
        <div>
          <Button className="btn-dark" onClick={this.signIn}>Sign In</Button>
        </div>
    }
    {isAuthenticated &&
      <div>
        <Navbar bg="black" variant="dark" expand="md">
          <Nav>
            <NavDropdown title={"Hello " + user.attributes.given_name}>
              <NavDropdown.Item onClick={this.signOut}>Sign Out</NavDropdown.Item>
              <NavDropdown.Item onClick={this.ChangePassword}>Change Password</NavDropdown.Item> 
            </NavDropdown>
          </Nav>
        </Navbar>
      </div>
    }      
      </div>
    )
  }
}

export default view(UserNav);
