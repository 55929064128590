import React from "react";
import { Row, Col, Button, Form } from 'react-bootstrap';
import { Auth } from 'aws-amplify';
import Header from "../partials/Header";
import Footer from "../partials/Footer";
import AppState from '../../support/AppState';

class SignUp extends React.Component {
  state = {
    username: '',
    password: '',
    email: '',
    phone_number: '',
    authCode: '',
    showConfirmation: false
  }
  
  componentWillUnmount() {
    const { resetAlertBanner } = AppState;
    resetAlertBanner();
  }
	
  handleChange = (event) => {
		this.setState({[event.target.id] : event.target.value})
  }

  signUp = (event)  => {
    event.preventDefault();
    console.log(this.state)
    const { email, password, given_name, family_name } = this.state
    const username = email;
    Auth.signUp({username, password, attributes: {email, given_name, family_name}})
    .then(user => {
      console.log('SignUp signUp user', user);
      if(user.userConfirmed){
        const { history } = AppState;
        history.push('/signin')   
      }else{
        this.setState({ showConfirmation: true })
      }
    })
    .catch(err => {
      console.log('SignUp signUp err', err);
      const { showAlertBanner } = AppState;
      showAlertBanner('danger', err.message, 10000);
     }); 
  }
  confirmSignUp = (event) => {
    event.preventDefault();
    Auth.confirmSignUp(this.state.email, this.state.authCode)
    .then(success => {
      // TODO: verify if success is a string and not a boolean.
      console.log('SignUp confirmSignUp success', success);
      if(success){
        const { history } = AppState;
        history.push('/signin');
      }else{
        alert('Bad code');
      }
     })
    .catch(err => {
      console.log('SignUp confirmSignUp err', err);
      const { showAlertBanner } = AppState;
      showAlertBanner('danger', err.message, 10000);
    }); 
  }
  resendSignUp = () => {
    const { showAlertBanner } = AppState;
    Auth.resendSignUp(this.state.email).then(() => {
      showAlertBanner('danger', 'Code resent successfully.', 10000);
    })
    .catch(err => {
      console.log('SignUp resendSignUp err', err);
      showAlertBanner('danger', err.message, 10000);
    }); 
  }

	render() {
    const { showConfirmation } = this.state
	  return (
      <div>
        <Header />
			  <div className="content">
  	      <Row>
  					<Col xs={{ span: 10, offset: 1 }} sm={{ span: 6, offset: 3 }} md={{ span: 4, offset: 4 }}>
              <div>
          { !showConfirmation && (
                <div>
      						<h2>Sign Up</h2>
      			      <Form onSubmit={ this.signUp }>
      			        <Form.Group>
      			          <Form.Label for="email">Email</Form.Label>
      			          <Form.Control type="email" name="email" id="email" placeholder="" onChange={this.handleChange} required />
      			        </Form.Group>
      			        <Form.Group>
      			          <Form.Label for="given_name">First name</Form.Label>
      			          <Form.Control type="text" name="given_name" id="given_name" placeholder="" onChange={this.handleChange} required />
      			        </Form.Group>
      			        <Form.Group>
      			          <Form.Label for="family_name">Last name</Form.Label>
      			          <Form.Control type="text" name="family_name" id="family_name" placeholder="" onChange={this.handleChange} required />
      			        </Form.Group>
      			        <Form.Group>
      			          <Form.Label for="password">Password</Form.Label>
      			          <Form.Control type="password" name="password" id="password" placeholder="" onChange={this.handleChange} required />
      			        </Form.Group>
      			        <Button color="primary" type="submit">Submit</Button>
      			      </Form>
                </div>
            )
          }
          {showConfirmation && (
                <div>
      						<h2>Sign Up</h2>
      			      <Form onSubmit={ this.confirmSignUp }>
      			        <Form.Group>
      			          <Form.Label for="authCode">Confirmation Code</Form.Label>
      			          <Form.Control type="text" name="authCode" id="authCode" placeholder="" onChange={this.handleChange} required />
                      <Form.Text>Check your spam folder if you do not see the email</Form.Text>
                    </Form.Group>
                    <Button color="primary" type="submit">Submit</Button> &nbsp;
                    <Button color="link" onClick={this.resendSignUp}>Resend Code</Button>
      			      </Form>
                </div>
            )
          }
              </div>
  					</Col>
  				</Row>
  			</div>
        <Footer />
      </div>
	  );
	}
};

export default SignUp;
