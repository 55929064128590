import React from "react";
import {SendRequest} from 'rtcmesh-react';
import { Row, Col, Button, Modal, Form } from 'react-bootstrap'
import Header from "../partials/Header";
import Footer from "../partials/Footer";
import AppState from '../../support/AppState';
import { order_set_fulfilled } from '../../support/order';

class Fulfillment extends React.Component {
  
  constructor(props) {
    if(process.env.REACT_APP_TRACE_FN_SEQ) console.debug('=*= Fulfillment constructor');
    super(props);
    this.state = { orders: null, orders_to_finalize: null, to_finalize_count : null}
  }
  
  componentDidMount(){
    if(process.env.REACT_APP_TRACE_FN_SEQ) console.debug('=*= Fulfillment componentDidMount');
    try{
      this.load_orders();
    } catch (error) {
      console.error(error);
      const { showAlertBanner } = AppState;
      if(showAlertBanner) showAlertBanner('danger', "An error occurred loading the orders", 8000);
    }
  }
  
  load_orders = () => {
    if(process.env.REACT_APP_TRACE_FN_SEQ) console.debug('=*= Fulfillment load_orders');
    let _this = this;
    // Load the orders to process first - processing
    let params = {filter : "data->'data'->>'status' = 'processing'"};
    SendRequest('retrieve', null, 'orders', params, function(response){
      console.debug('Fulfillment load_orders processing response', response)
      if(response.code === 200){
        console.debug('Fulfillment load_orders processing response.data.orders', response.data.orders)
        _this.setState({orders : response.data.orders})
        // Then load the ones to finalize - processed
        _this.load_orders_to_finalize();
      }
    });
  }
  
  load_orders_to_finalize = () => {
    if(process.env.REACT_APP_TRACE_FN_SEQ) console.debug('=*= Fulfillment load_orders_to_finalize');
    let _this = this;
    let params = {filter : "data->'data'->>'status' = 'processed'"};
    SendRequest('retrieve', null, 'orders', params, function(response){
      console.debug('Fulfillment load_orders_to_finalize processed response', response)
      if(response.code === 200){
        console.debug('Fulfillment load_orders_to_finalize processed response.data.orders', response.data.orders)
        _this.setState({orders_to_finalize : response.data.orders, to_finalize_count : response.data.orders.length});
      }
    });
  }
  
  process_order = (product_id) => {
    if(process.env.REACT_APP_TRACE_FN_SEQ) console.debug('=*= Fulfillment process_order');
    this.props.history.push('/processorder/' + product_id);   // router4 passes history
  }
  
  finalize_processed_orders = () => {
    if(process.env.REACT_APP_TRACE_FN_SEQ) console.debug('=*= Fulfillment finalize_processed_orders');
    // Open the wait modal
    const { assets_url_prefix } = AppState;
    let _this = this, theJsx = (<div className="text-center"><img src={assets_url_prefix + 'wait.webp'} /></div>)
    this.setState({ modal_title :'Updating order(s) status', modal_content_jsx : theJsx, modalIsOpen: true });
    // Cahange status to the orders_to_finalize to fulfilled.
    let orders_id = [];
    for(var i = 0, len = this.state.orders_to_finalize.length; i < len; i++){
      orders_id.push(this.state.orders_to_finalize[i].id);
    }
    console.debug('Fulfillment finalize_processed_orders orders_id', orders_id)
    order_set_fulfilled(orders_id, function(response){
      console.debug('Fulfillment finalize_processed_orders response', response)
      _this.setState({ modalIsOpen: false });
      const { showAlertBanner } = AppState;
      if(response.code === 200){
        if(showAlertBanner) showAlertBanner('success', response.message, 8000);
        _this.setState({ to_finalize_count : null });
        _this.load_orders_to_finalize();
      }else{
        if(showAlertBanner) showAlertBanner('danger', response.message, 8000);
      }
    });
  }
  
  hideModal = () => {
    this.setState({ modalIsOpen: false });
  };
  
	render() {
		if(process.env.REACT_APP_TRACE_FN_SEQ) console.debug('=*= Fulfillment render');
    const process_order = this.process_order;
	  return (
      <div>
        <Header />
			  <div className="content">
          <Modal show={this.state.modalIsOpen} backdrop="static" onHide={this.hideModal}>
            <Modal.Header closeButton>
              <Modal.Title>{this.state.modal_title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.state.modal_content_jsx}
            </Modal.Body>
          </Modal>
          <h2>Finalize Processed Orders</h2>
      { this.state.to_finalize_count === null && <div>Loading...</div> }
      { this.state.to_finalize_count !== null && (this.state.to_finalize_count > 0) &&
        <div className="mb-3">
            <Form.Check type="checkbox" label={'Check that there ' + (this.state.to_finalize_count == 1 ? 'is ' : 'are ') +
            this.state.to_finalize_count + ' envelope' + (this.state.to_finalize_count == 1 ? '' : 's') +
            ' in the outgoing bin.'} />
            <Form.Check type="checkbox" label="Bring the outgoing bin to the post office." />
            <Button variant="primary" size="sm" onClick={this.finalize_processed_orders}>Finalize Processed Orders</Button>
          </div>
      }
          <h2>Orders to Process</h2>
      { !this.state.orders && <div>Loading...</div> }
      { this.state.orders && this.state.orders.map(function(item, idx){
          var theJsx = [];
          theJsx.push(
            <Row key={item.id}>
              <Col xs="auto">
                <Button variant="primary" size="sm" onClick={() => process_order(item.id)}>Process Order</Button>
              </Col>
              <Col>
            <div>Placed on {new Date(item.data.order_date).toDateString()}</div>
              </Col>
            </Row>
          );
          return (<li key={'row' + idx} className = "list-group-item">{theJsx}</li>);
      })}
			  </div>
        <Footer />
      </div>
	  );
	}
};

export default Fulfillment;