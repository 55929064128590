import React from 'react'
// import logo from '../../logo.png';
// import { view } from 'react-easy-state';
// import { Row, Col } from 'react-bootstrap';
import { Navbar, Nav } from 'react-bootstrap';
// import UserNav from './UserNav';
// import SiteNav from './SiteNav';
// import AlertBanner from './AlertBanner';
// import AppState from '../../support/AppState';

class Footer extends React.Component { 
  
  render() {
    return (
      <footer className="App-footer">
        <Navbar bg="black" variant="dark" expand="md">
          <Nav>
            <Nav.Item>
              <a href="mailto:support@theclothmask.com" target="_blank" >Contact Us</a>
      
            </Nav.Item>
          </Nav>
        </Navbar>
      </footer>
    );
  }
}

export default Footer;
