import React from "react";
import { Row, Col, Button, Form } from 'react-bootstrap';
import { Auth } from 'aws-amplify';
import Header from "../partials/Header";
import Footer from "../partials/Footer";
import AppState from '../../support/AppState';

class ChangePassword extends React.Component {
  state = {
    password: '',
    new_password: ''
  }
  
  componentWillUnmount() {
    const { resetAlertBanner } = AppState;
    resetAlertBanner();
  }
	
  handleChange = (event) => {
		this.setState({[event.target.id] : event.target.value})
  }

  changePassword = (event)  => {
    event.preventDefault();
    Auth.currentAuthenticatedUser()
    .then(user => {
      Auth.changePassword(user, this.state.password, this.state.new_password)
      .then(data => {
        console.log('ForgotPassword changePassword data', data);
        if(data === 'SUCCESS'){
          const { history } = AppState;
          history.push('/');
        }
      })
      .catch(err => {
        console.log('ChangePassword changePassword changePassword err', err);
        const { set_prop } = AppState;
        let AlertBanner = {visible : true, color : 'danger', text : err.message}
        set_prop("AlertBanner", AlertBanner); 
      });
    })
    .then(data => console.log(data))
    .catch(err => {
      console.log('ChangePassword changePassword currentAuthenticatedUser err', err);
      const { set_prop } = AppState;
      let AlertBanner = {visible : true, color : 'danger', text : err.message}
      set_prop("AlertBanner", AlertBanner); 
    });
    
  }

	render() {
	  return (
      <div>
        <Header />
			  <div className="content">
  	      <Row>
  					<Col xs={{ span: 10, offset: 1 }} sm={{ span: 6, offset: 3 }} md={{ span: 4, offset: 4 }}>
              <div>
                <div>
      						<h2>Change Password</h2>
      			      <Form onSubmit={ this.changePassword }>
      			        <Form.Group>
      			          <Form.Label for="password">Current Password</Form.Label>
      			          <Form.Control type="password" name="password" id="password" placeholder="" onChange={this.handleChange} required />
      			        </Form.Group>
      			        <Form.Group>
      			          <Form.Label for="new_password">New Password</Form.Label>
      			          <Form.Control type="password" name="new_password" id="new_password" placeholder="" onChange={this.handleChange} required />
      			        </Form.Group>
      			        <Button color="primary" type="submit">Submit</Button>
      			      </Form>
                </div>
              </div>
  					</Col>
  				</Row>
  			</div>
        <Footer />
      </div>
	  );
	}
};

export default ChangePassword;
