import React from "react";
import { SendRequest } from 'rtcmesh-react';
import { Row, Col, Form, Button, Modal } from 'react-bootstrap'
import Header from "../partials/Header";
import Footer from "../partials/Footer";
import AppState from '../../support/AppState';
import { add_item_to_cart } from '../../support/cart';
import sizeChartPic from '../../DSC_0342_SizeChart.JPG';

class Product extends React.Component {
  
  constructor(props) {
    if(process.env.REACT_APP_TRACE_FN_SEQ) console.log('=*= Product constructor');
    super(props);
    this.state = { 
      product: {}, 
      skus : [], 
      curr_color : '', 
      colors : [], 
      sizes : [], 
      size_enable : [],
      curr_size : '', 
      curr_img_idx : 0,
      quantity : '1',
      add_to_cart_disabled : true,
      in_stock : undefined,
      modalIsOpen: false,
      sizeChartModalIsOpen: false,
    }
  }
  
  componentDidMount(){
    if(process.env.REACT_APP_TRACE_FN_SEQ) console.log('=*= Product componentDidMount');
    this.load_product_and_skus();
  }
  
  load_product_and_skus = () => {
    if(process.env.REACT_APP_TRACE_FN_SEQ) console.log('=*= Product load_product_and_skus');
    let _this = this;
    let params = {id : this.props.match.params.id}; // match is passed as a prop by router4
    SendRequest('retrieve', null, 'product', params, function(response){
      console.debug('Product load_product_and_skus product response', response)
      if(response.code === 200){
        if(! response.data.product.json_data.active){
          const { showAlertBanner } = AppState;
          alert('This product is no longer sold.')
          return;
        }
        let product = _this.attr2obj(response.data.product.json_data);
        console.debug('product', product)
        params = {filter : "data->'data'->'json_data'->>'product_id' = '" + response.data.product.id + "'"};
        SendRequest('retrieve', null, 'skus', params, function(response){
          console.log('Product load_product_and_skus skus response', response)
          if(response.code === 200){
            let skus = _this.process_skus(response.data.skus);
            console.debug('Product load_product_and_skus skus', skus);
            // Set colors and sizes - arrays are reversed so map will show them in the orginal order.
            console.debug('Product load_product_and_skus color', product.attribute.color)
            let colors = _this.filter_colors(product.attribute.color, skus);
            _this.setState({colors : colors});
            let description_by_color = _this.get_description_by_color(colors, skus);
            _this.setState({description_by_color : description_by_color});
            let sizes = product.attribute.size.split(',').reverse();
            _this.setState({sizes : sizes});
            // Set product and skus.
            _this.setState({skus : skus});
            _this.setState({product : product});
            // Set the current color - we put it on a timer to allow setState to run
            let sku_id = new URLSearchParams(_this.props.location.search).get("sku_id");
            if(sku_id){
              for(var i = 0, len = skus.length; i < len; i++){
                if(skus[i].id === parseInt(sku_id)){
                  setTimeout(() => _this.set_curr_color(skus[i].attribute.color), 100);
                  setTimeout(() => _this.set_curr_size(skus[i].attribute.size), 120);
                  break;
                }
              }
            }else{
              setTimeout(() => _this.set_curr_color(colors[colors.length - 1].trim()), 100);
            }
          }
        });
      }
    });
  }
  
  // Convert the attribute array to object so we can get sizes and colors without looping 
  attr2obj = (json_data) => {
    if(process.env.REACT_APP_TRACE_FN_SEQ) console.log('=*= Product attr2obj');
    let new_attribute = {};
    for(var i = 0, len = json_data.attribute.length; i < len; i++){
      new_attribute[json_data.attribute[i].name] = json_data.attribute[i].value;
    }
    json_data.attribute = new_attribute;
    console.debug('json_data', json_data)
    return json_data;
  }
  
  // Scan the skus array, remove the extra data element and convert the attribute array to object 
  // so we can get sizes and colors without looping 
  process_skus = (skus) => {
    if(process.env.REACT_APP_TRACE_FN_SEQ) console.log('=*= Product process_skus');
    let new_skus = [];
    for(var i = 0, len = skus.length; i < len; i++){
      let sku = this.attr2obj(skus[i].data.json_data);
      new_skus.push(sku)
    }
    return new_skus;
  }
  
  // If ALL the skus have zero quantity and `can_backorder = false` we remove the color because it is no longer available.
  // If there is at least one sku with one or more items available we keep the `color`.
  filter_colors = (colors_string, skus) => {
    if(process.env.REACT_APP_TRACE_FN_SEQ) console.log('=*= Product filter_colors');
    let colors = colors_string.split(',').reverse();
    console.debug('filter_colors start colors', colors)
    for(var i = 0, len = colors.length; i < len; i++){
      let all_color_skus = this.get_all_color_skus(colors[i], skus);
      console.debug('filter_colors all_color_skus', all_color_skus)
      if(skus[i].can_backorder){
        break;
      }
      let remove_color = true;
      for(var j = 0, jlen = all_color_skus.length; j < jlen; j++){
        if(parseInt(all_color_skus[j].quantity) > 0){
          remove_color = false;
          break;
        }
      }
      if(remove_color){
        console.log('remove_color', colors[i])
        colors.splice(i, 1);
        console.log('colors',colors)
        len--; // The array is now shorter because we removed an element.
      }
    }
    console.debug('filter_colors filtered colors', colors)
    return colors;
  }
  
  get_all_color_skus = (color, skus) => {
    if(process.env.REACT_APP_TRACE_FN_SEQ) console.log('=*= Product get_all_color_skus');
    let all_color_skus = [];
    for(var i = 0, len = skus.length; i < len; i++){
      if(skus[i].attribute.color === color){
        all_color_skus.push(skus[i]);
      }
    }
    console.debug('get_all_color_skus all_color_skus colors', all_color_skus)
    return all_color_skus;
  }
  
  get_curr_sku = () => {
    if(process.env.REACT_APP_TRACE_FN_SEQ) console.log('=*= Product get_curr_sku');
    // Get a sku that matches the selected size and color. 
    for(var i = 0, len = this.state.skus.length; i < len; i++){
      let sku = this.state.skus[i];
      if(sku.attribute.color === this.state.curr_color && sku.attribute.size === this.state.curr_size){
        return sku;
      }
    }
    return null;
  }
  
  get_description_by_color = (colors, skus) => {
    if(process.env.REACT_APP_TRACE_FN_SEQ) console.log('=*= Product get_description_by_color');
    let description_by_color = {}
    for(var i = 0, len = colors.length; i < len; i++){
      let color = colors[i];
      for(var j = 0, jlen = skus.length; j < jlen; j++){
        if(skus[j].attribute.color === color){
          description_by_color[color] = skus[j].description;
        }
      }
    }
    return description_by_color;
  }
  
  // Set size controls based on availability
  reset_size_controls = (curr_color) => {
    if(process.env.REACT_APP_TRACE_FN_SEQ) console.log('=*= Product reset_size_controls');
    // Disable unavailable sizes if can_backorder = false 
    let all_color_skus = this.get_all_color_skus(curr_color, this.state.skus), sizes = [], size_enable = [];
    for(var i = 0, len = all_color_skus.length; i < len; i++){
      let sku = all_color_skus[i];
      console.debug('reset_size_controls sku', sku)
      if(sku.can_backorder){
        size_enable.push(sku.attribute.size.toLowerCase());
      }else if(parseInt(sku.quantity) > 0){
        size_enable.push(sku.attribute.size.toLowerCase());
      }
    }
    this.setState({size_enable : size_enable});
    
    // Reset current size if not available.
    if(size_enable.indexOf(this.state.curr_size) === -1){
      this.setState({curr_size : ''});
    }
  }
  
  reset_controls_state = () => {
    if(process.env.REACT_APP_TRACE_FN_SEQ) console.log('=*= Product reset_controls_state');
    // We set it on timeout to allow setState to run.
    setTimeout(function(_this){
      if(process.env.REACT_APP_TRACE_FN_SEQ) console.log('=*= Product reset_controls_state setTimeout');
      // In Stock
      let curr_sku = _this.get_curr_sku();
      if(curr_sku){
        if(parseInt(curr_sku.quantity) >= _this.state.quantity){
          _this.setState({in_stock : true});
        }else{
          _this.setState({in_stock : false});
        }
      }else{
        _this.setState({in_stock : undefined});
      }     
      // Add to cart. 
      if(!_this.state.curr_size || (parseInt(_this.state.quantity) < 1 || !parseInt(_this.state.quantity))){
        _this.setState({add_to_cart_disabled : true});
      }else{
        _this.setState({add_to_cart_disabled : false});
      }
    }, 200, this)
  }

  set_curr_color = (curr_color) => {
    if(process.env.REACT_APP_TRACE_FN_SEQ) console.log('=*= Product set_curr_color');
    this.setState({curr_color : curr_color.trim()});
    // We set it on timeout to allow setState to run.
    setTimeout(function(_this){
      _this.reset_size_controls(curr_color);
      _this.reset_controls_state();
    }, 200, this);
  }
  
  set_curr_size = (new_size) => {
    if(process.env.REACT_APP_TRACE_FN_SEQ) console.log('=*= Product set_curr_size');
    this.setState({curr_size : new_size.trim()});
    this.reset_controls_state();
  }
  
  set_quantity = (event) => {
    if(process.env.REACT_APP_TRACE_FN_SEQ) console.log('=*= Product set_quantity');
    this.setState({quantity : event.target.value});
    this.reset_controls_state();
  }
  
  showModal = () => {
    this.setState({ modalIsOpen: true });
  };
  hideModal = () => {
    this.setState({ modalIsOpen: false });
  };
  
  showSizeChartModal = () => {
    this.setState({ sizeChartModalIsOpen: true });
  };
  hideSizeChartModal = () => {
    this.setState({ sizeChartModalIsOpen: false });
  };
  
  go_to_cart = () => {
    const {history} = AppState;   // history set in SiteNav
    history.push('/cart');
  }
    
  add_to_cart = () => {
    if(process.env.REACT_APP_TRACE_FN_SEQ) console.log('=*= Product add_to_cart');
    let curr_sku = this.get_curr_sku(), _this = this;
    if(curr_sku){
      // Pop the modal with the wait gif and then call the async function to add the item to the cart.
      const { assets_url_prefix } = AppState;
      let theJsx = (<div className="text-center"><img src={assets_url_prefix + 'wait.webp'} /></div>)
      this.setState({modal_content_jsx : theJsx});
      this.showModal();
      add_item_to_cart(curr_sku, this.state.quantity, this.state.product.name, this.state.product.price, function(cart_id){
        console.log('add_to_cart callback ', cart_id)
        let theJsx = (
          <div>
            <h4 className="text-center">Item added to cart.</h4>
            <Button variant="dark" block onClick={_this.hideModal}>Continue Shopping</Button>   
            <Button variant="dark" block onClick={_this.go_to_cart}>Go To Cart</Button>   
          </div>
        )
        _this.setState({modal_content_jsx : theJsx});
      });
    }else{
      const { showAlertBanner } = AppState;
      showAlertBanner('danger', "Error finding SKU.", 8000);
    }
  }
  
	render() {
    if(process.env.REACT_APP_TRACE_FN_SEQ) console.log('=*= Product render');
    // console.log('Product');
    const { assets_url_prefix } = AppState;
    const product_id = this.state.product.id, 
      product_type = this.state.product.product_type, 
      description_by_color = this.state.description_by_color,
      curr_color = this.state.curr_color, 
      colors = this.state.colors,
      set_curr_color = this.set_curr_color,
      curr_size = this.state.curr_size,
      sizes = this.state.sizes,
      size_enable = this.state.size_enable,
      set_curr_size = this.set_curr_size,
      curr_img_idx = this.state.curr_img_idx;
	  return (
      <div>
        <Header />
			  <div className="content">
          <Modal show={this.state.modalIsOpen} backdrop="static" onHide={this.hideModal}>
            <Modal.Header closeButton>
              <Modal.Title>Add To Cart</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.state.modal_content_jsx}
            </Modal.Body>
          </Modal>
          <Modal show={this.state.sizeChartModalIsOpen} onHide={this.hideSizeChartModal}>
            <Modal.Header closeButton>
              <Modal.Title>Size Chart</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div><img src={sizeChartPic} className="img-fluid" /></div>
              <div>Measure from the top of the nose to the bottom of the chin.</div>
              <div>Select large if more than 6-1/4"</div>
              <div>Select medium if between 5-1/2" and 6-1/4"</div>
              <div>Select small if less than 5-1/2"</div>
            </Modal.Body>
          </Modal>
      { ! product_id && <h5>Loading...</h5>}
      { product_id &&
          <Row className="mt-2">
            <Col sm={1} md={1} >      
              <img className="img-thumbnail" src={assets_url_prefix + product_id + '_' + curr_color + '_thumb' + curr_img_idx + '.jpg'} />
            </Col>
            <Col sm={11} md={6}>
              <img src={assets_url_prefix + product_id + '_' + curr_color + '_img' + curr_img_idx + '.jpg'}  className="img-fluid" />
            </Col>
            <Col sm={12} md={5}>
              <h2>{this.state.product.name}</h2>
              <h4>{description_by_color[curr_color]}</h4>
              <h4>${this.state.product.price}</h4>
              <Row>
                <Col sm={3} md={3}>
                  <div>Size:</div>
              {product_type == 'mask' &&
                  <div><Button variant="outline-secondary" size="sm" onClick={this.showSizeChartModal}>Size&nbsp;Chart</Button></div>
              }
                </Col>
                <Col sm={9} md={9} className="text-right">
          { sizes.map(function(item, idx){
                  if(size_enable.indexOf(item) === -1){
                    return (<Button variant="dark" className="m-2" disabled>{item}</Button>);
                  }else{
                    if(item == curr_size){
                      return (<Button variant="dark" className="m-2" onClick={() => set_curr_size(item)}>{item}</Button>);
                    }else{
                      return (<Button variant="outline-dark" className="m-2" onClick={() => set_curr_size(item)}>{item}</Button>);
                    }
                  }
          })}       
                </Col>
              </Row>
              <Row className="mt-2" >
                <Col sm={2} md={2}>Color:</Col>
                <Col sm={10} md={10} className="text-right">
          { colors.map(function(item, idx){
                  return (<div key={'row' + idx} className="float-right  m-1">
                    <img className="img-thumbnail" src={assets_url_prefix + product_id + '_' + item + '_swatch.jpg'} 
                    onClick={() => set_curr_color(item)} className="cursor_pointer" />
                  </div>);
          })} 
                </Col>
              </Row> 
              <Row className="mt-3" >
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm={2}>Quantity: </Form.Label>
                    <Col sm={10}>
                      <Form.Control type="number" defaultValue={this.state.quantity} onChange={this.set_quantity}/>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>     
              <Row >
                <Col className={this.state.in_stock ? 'text-success font-weight-bold' : 'text-danger'}>          
                  {this.state.in_stock === undefined ? '' : (this.state.in_stock ? 'In Stock' : 'On Backorder')} 
                </Col>
              </Row>     
              <Row className="mt-3" >
                <Col>
                  <Button variant="dark" block onClick={this.add_to_cart} disabled={this.state.add_to_cart_disabled ? 'disabled' : ''}>
                    ADD TO CART
                  </Button>   
                </Col>
              </Row> 
            </Col>
          </Row>
      }
			  </div>
        <Footer />
      </div>
	  );
	}
};

export default Product;