import {SendRequest} from 'rtcmesh-react';
import AppState from './AppState';

// TODO: use localStorage.removeItem('cart_id'); after converting the cart to an order
//       use localStorage.setItem('cart_id', 'Tom'); to set the cart id

// Order functions to support the checkout and dulfillment pages.
export {
  init_order,
  order_validate_address,
  order_set_valid_address,
  order_place_order,
  order_set_processed,
  order_set_fulfilled,
  order_get_order,
  order_save_order,
  order_local_reset,
};

// Load the order for the current cart if there is one, otherwise it creates an empty order.
const init_order = function(callback){
  if(process.env.REACT_APP_TRACE_FN_SEQ) console.log('=*= order init_order');
  // Look for a cart ID in local storage.  Retrieve its corresponding order if found, else create an empty order.
  const cart_id = localStorage.getItem('cart_id');
  if(cart_id){
    let params = {filter : "data->'data'->>'cart_id' = '" + cart_id + "'"};
    console.debug('order init_order retrieve order params', params)
    SendRequest('retrieve', null, 'orders', params, function(response){
      console.debug('order init_order retrieve order response', response)
      if(response.code === 200){
        if(response.data.orders[0]){
          AppState.order = response.data.orders[0].data;
          console.debug('order init_order retrieve order AppState.order', AppState.order);
          callback();
        }else{
          let order_id = Math.uuid();  // Loaded in /public
          let order = {
            id : order_id,
            cart_id : cart_id,
            status : "in_progress",
            created : new Date().toISOString(),
            updated : new Date().toISOString()
          }
          SendRequest('crt_updt', null, 'order', {id : order_id, data : order},function(response){
            if(response.code === 200){
              console.log('order init_order crt_updt order response', response);
              AppState.order = order;
            }
            callback();
          });
        }
      }
    });
  }
}

// Send a user entered address to be validated by the USPS API.
const order_validate_address = function(address, callback){
  if(process.env.REACT_APP_TRACE_FN_SEQ) console.log('=*= order order_validate_address');
  // Call patch to validate and add the address.
  let params = {op : 'validate_address', address : address}
  SendRequest('patch', null, 'orders', params, function(response){
    console.debug('order order_validate_address patch validate_address response', response)
    callback(response);
  });
}
// Call patch to set the address in the order.
const order_set_valid_address = function(valid_address, callback){
  if(process.env.REACT_APP_TRACE_FN_SEQ) console.log('=*= order order_set_valid_address');
  let params = {op : 'set_valid_address', address : valid_address, order_id : AppState.order.id}
  SendRequest('patch', null, 'orders', params, function(response){
    console.debug('order order_validate_address patch validate_address response', response);
    if(response.code === 200) AppState.order.address = valid_address;
    callback(response);
  });
}

// Call patch to authorize the card.
const order_place_order = function(payment, callback){
  if(process.env.REACT_APP_TRACE_FN_SEQ) console.log('=*= order order_place_order');
  let params = {op : 'place_order', payment : payment}
  SendRequest('patch', null, 'orders', params, function(response){
    console.debug('order order_place_order patch place_order response', response);
    callback(response);
  });
}

// Capture the transaction and update the status to processed.
const order_set_processed = function(order_id, callback){
  if(process.env.REACT_APP_TRACE_FN_SEQ) console.log('=*= order order_set_processed');
  let params = {op : 'set_processed', id : order_id}
  SendRequest('patch', null, 'orders', params, function(response){
    console.debug('order order_set_processed patch set_processed response', response);
    callback(response);
  });
}

// Capture the transaction and update the status to processed.
const order_set_fulfilled = function(orders_id, callback){
  if(process.env.REACT_APP_TRACE_FN_SEQ) console.log('=*= order order_set_fulfilled');
  let params = {op : 'set_fulfilled', orders_id : orders_id}
  SendRequest('patch', null, 'orders', params, function(response){
    console.debug('order order_set_fulfilled patch set_fulfilled response', response);
    callback(response);
  });
}

// Retrieve and set an order in AppState
const order_get_order = function(order_id, callback){
  if(process.env.REACT_APP_TRACE_FN_SEQ) console.log('=*= order order_place_order');
  SendRequest('retrieve', null, 'order', {id : order_id},function(response){
    if(response.code === 200 && response.data.order){
      console.debug('order order_get_order retrieve order response', response);
      AppState.order = response.data.order.data;
    }
    callback();
  });
}

// Save an order and set it in AppState
const order_save_order = function(order, callback){
  if(process.env.REACT_APP_TRACE_FN_SEQ) console.log('=*= order order_save_order');
  SendRequest('crt_updt', null, 'order', {id : order.id, data : order},function(response){
    if(response.code === 200){
      console.debug('order order_save_order crt_updt order response', response);
      AppState.order = order;
    }
    callback();
  });
}

const order_local_reset = function(){
  AppState.order = null;
}

// ======================================
// Not exported module support functions.
// ======================================

