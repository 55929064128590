import { store } from 'react-easy-state'

const AppState = store({
  ws : null,
  user : null,
  ws_response : {},
  isAuthenticated : false,
  AlertBanner : {visible : false, color : 'light', text : ''},
  jsonb_schema_product_form_layout : null,
  jsonb_schema_sku_form_layout : null,
  assets_url_prefix : process.env.REACT_APP_ASSETS_URL_PREFIX,
  cart : null,
  order : null,
  redirect_login_url : '/',
  set_ws(websocket){
    AppState.ws = websocket;
    if(websocket){
      AppState.connected_class = 'text-success';
    }else{
      AppState.connected_class = 'text-danger';
    }
  },
  get_ws() {
    return AppState.ws;
  },
  set_prop(key, val){
    console.log('AppState - setting', key, '=', val)
    AppState[key] = val;
  }
})

export default AppState