import React from 'react'
import { withRouter, Switch, Route, Redirect, BrowserRouter as Router } from 'react-router-dom'
import { Auth } from 'aws-amplify'
import Cart from "../components/pages/Cart";
import Checkout from "../components/pages/Checkout";
import Products from "../components/pages/Products";
import Product from "../components/pages/Product";
import SignIn from "../components/pages/SignIn";
import SignUp from "../components/pages/SignUp";
import Catalog from "../components/pages/Catalog";
import Inventory from "../components/pages/Inventory";
import Fulfillment from "../components/pages/Fulfillment";
import ProcessOrder from "../components/pages/ProcessOrder";
import Customers from "../components/pages/Customers";
import ForgotPassword from "../components/pages/ForgotPassword";
import ChangePassword from "../components/pages/ChangePassword";
import OrderStatus from "../components/pages/OrderStatus";

class PrivateRoute extends React.Component {
  state = {
    loaded: false,
    isAuthenticated: false
  }
  componentDidMount() {
    this.authenticate()
    this.unlisten = this.props.history.listen(() => {
      Auth.currentAuthenticatedUser()
        .then(user => console.log('user: ', user))
        .catch(() => {
          if (this.state.isAuthenticated) this.setState({ isAuthenticated: false })
        })
    });
  }
  componentWillUnmount() {
    this.unlisten()
  }
  authenticate() {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        this.setState({ loaded: true, isAuthenticated: true })
      })
      .catch(() => this.props.history.push('/signin'))
  }
  render() {
    const { component: Component, ...rest } = this.props
    const { loaded , isAuthenticated} = this.state
    if (!loaded) return null
    return (
      <Route
        {...rest}
        render={props => {
          return isAuthenticated ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: "/signin",
              }}
            />
          )
        }}
      />
    )
  }
}

PrivateRoute = withRouter(PrivateRoute)

const Routes = () => (
  <Router basename = {process.env.REACT_APP_BASENAME}>
    <Switch>
      <Route path='/cart' component={Cart} />
      <Route path='/checkout' component={Checkout} />
      <Route path='/products' component={Products} />
      <Route path='/product/:id' component={Product} />
      <Route path='/signin' component={SignIn} />
      <Route path='/signup' component={SignUp} />
      <Route path='/forgotpassword' component={ForgotPassword} />
      <Route path='/changepassword' component={ChangePassword} />
      <Route path='/orderstatus/:id' component={OrderStatus} />
    	<Route exact path="/" render={() => (
    		<Redirect to="/products"/> )
    	}/>
      <PrivateRoute path='/catalog' component={Catalog} />
      <PrivateRoute path='/inventory' component={Inventory} />
      <PrivateRoute path='/fulfillment' component={Fulfillment} />
      <PrivateRoute path='/customers' component={Customers} />
      <PrivateRoute path='/processorder/:id' component={ProcessOrder} />
    </Switch>
  </Router>
)

export default Routes
