import React from "react";
import { Row, Col, Button, Modal } from 'react-bootstrap'
import Header from "../partials/Header";
import Footer from "../partials/Footer";
import AppState from '../../support/AppState';
import { view } from 'react-easy-state';
import { SendRequest } from 'rtcmesh-react';
import { SendRequestAsync } from '../../support/SendRequestAsync';
import { GenForm, genFormReset } from '../../support/bootstrap-formgen';

class Catalog extends React.Component {
  
  constructor(props) {
    if(process.env.REACT_APP_TRACE_FN_SEQ) console.debug('=*= Catalog constructor');
    super(props);
    this.state = { formData: {}, modalIsOpen: false, products: null }
  }
  
  async componentDidMount(){
    if(process.env.REACT_APP_TRACE_FN_SEQ) console.debug('=*= Catalog componentDidMount');
    try{
      const { jsonb_schema_product_form_layout } = AppState;
      console.debug('Catalog jsonb_schema_product_form_layout', jsonb_schema_product_form_layout)
      if(!jsonb_schema_product_form_layout) await this.load_jsonb_schema_product_form_layout();
      //
      await this.load_products();
    } catch (error) {
      console.error(error);
      const { showAlertBanner } = AppState;
      if(showAlertBanner) showAlertBanner('danger', "An error occurred loading the products", 8000);
    }
  }
  
  load_jsonb_schema_product_form_layout = async () => {
    if(process.env.REACT_APP_TRACE_FN_SEQ) console.debug('=*= load_jsonb_schema_product_form_layout constructor');
    const response =  await SendRequestAsync('retrieve', null, 'jsonb_schema_form_layout', {schema_name : 'products'});
    console.debug('Catalog load_jsonb_schema_product_form_layout response.code', response.code)
    const { set_prop } = AppState;
    if(response.code === 200){
      console.debug('Catalog load_jsonb_schema_product_form_layout response.data', response.data)
      set_prop('jsonb_schema_product_form_layout', response.data);
    }
  }
  
  load_products = async () => {
    if(process.env.REACT_APP_TRACE_FN_SEQ) console.debug('=*= Catalog load_products');
    const response =  await SendRequestAsync('retrieve', null, 'products', null);
    console.debug('Catalog load_products response', response)
    if(response.code === 200){
      console.debug('Catalog load_products response.data.products', response.data.products)
      this.setState({products : response.data.products})
    }
  }
  
  save_form_data = (form_data) => {
    if(process.env.REACT_APP_TRACE_FN_SEQ) console.debug('=*= Catalog save_form_data');
    console.debug('Catalog save_form_data form_data', form_data)
    this.hideModal();
    let _this = this;
    SendRequest('crt_updt', null, 'product', {id : form_data.id, data : form_data},function(response){
      if(response.code === 200){
        console.debug('Catalog save_form_data response', response);
        _this.setState({ formData: {} });
        _this.load_products();
      }
    });
  }

  showModal = () => {
    if(process.env.REACT_APP_TRACE_FN_SEQ) console.debug('=*= Catalog showModal');
    this.setState({ modalIsOpen: true });
  };

  hideModal = () => {
    if(process.env.REACT_APP_TRACE_FN_SEQ) console.debug('=*= Catalog hideModal');
    this.setState({ modalIsOpen: false });
    this.setState({formData : {}});
    genFormReset();
  };
  
  edit_product = (id) =>{
    if(process.env.REACT_APP_TRACE_FN_SEQ) console.debug('=*= Catalog edit_product');
    // Set the data and show the form
    for(var i = 0, len = this.state.products.length; i < len; i++){
      console.debug(this.state.products[i].id, '-', id)
      if(this.state.products[i].id == id){
        console.debug('formData', this.state.products[i].data.formData)
        this.setState({formData : this.state.products[i].data.formData});
        this.showModal();
      }
    }
  }
  
	render() {
		if(process.env.REACT_APP_TRACE_FN_SEQ) console.debug('=*= Catalog render');
    const { jsonb_schema_product_form_layout } = AppState;
    console.debug('Catalog render jsonb_schema_product_form_layout', jsonb_schema_product_form_layout)
    console.debug('Catalog render this.state.products', this.state.products)
    const edit_product = this.edit_product;
	  return (
      <div>
        <Header />
			  <div className="content">
      {! jsonb_schema_product_form_layout && ! this.state.products && 
          <div>Loading...</div>
      }
      {jsonb_schema_product_form_layout &&
          <div>
            <Button variant="primary" onClick={this.showModal}>Add Product</Button>
            <Modal show={this.state.modalIsOpen} backdrop="static" onHide={this.hideModal}>
              <Modal.Header closeButton>
                <Modal.Title>Product</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <GenForm jsonb_schema_form_layout={jsonb_schema_product_form_layout} cancel_btn_action={this.hideModal}
                return_form_data={this.save_form_data} formData={this.state.formData}/>
              </Modal.Body>
            </Modal>
          </div>
      }          
          <h2>Products</h2>
      {jsonb_schema_product_form_layout && this.state.products && this.state.products.map(function(item, idx){
          var theJsx = [];
          theJsx.push(
            <Row key={item.id}>
              <Col xs="auto">
                <Button variant="primary" size="sm" onClick={() => edit_product(item.data.id)}>Edit Product</Button>
              </Col>
              <Col>
                <div>{item.data.json_data.name}</div>
                <div>{item.data.json_data.attribute[0].name}: {item.data.json_data.attribute[0].value}</div>
              </Col>
            </Row>
          );
          return (<li key={'row' + idx} className = "list-group-item">{theJsx}</li>);
      })}
			  </div>
        <Footer />
      </div>
	  );
	}
};

export default view(Catalog);