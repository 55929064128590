import React from "react";
import { Row, Col, Button, Form } from 'react-bootstrap';
import { Auth } from 'aws-amplify';
import Header from "../partials/Header";
import Footer from "../partials/Footer";
import AppState from '../../support/AppState';

class ForgotPassword extends React.Component {
  state = {
    username: '',
    new_password: '',
    authCode: '',
    showCode: false
  }
  
  componentWillUnmount() {
    const { resetAlertBanner } = AppState;
    resetAlertBanner();
  }
	
  handleChange = (event) => {
		this.setState({[event.target.id] : event.target.value})
  }

  forgotPassword = (event)  => {
    event.preventDefault();
    const { set_prop } = AppState;
    let AlertBanner = {visible : false, color : 'light', text : ''}
    set_prop("AlertBanner", AlertBanner);
    const { username } = this.state
    Auth.forgotPassword(username)
    .then(data => {
      console.log('ForgotPassword forgotPassword data', data);
      this.setState({ showCode: true });
    })
    .catch(err => {
      console.log('ForgotPassword forgotPasswordSubmit err', err);
      const { set_prop } = AppState;
      let AlertBanner = {visible : true, color : 'danger', text : err.message}
      set_prop("AlertBanner", AlertBanner); 
     });    
  }
  forgotPasswordSubmit = (event) => {
    event.preventDefault();
    Auth.forgotPasswordSubmit(this.state.username, this.state.authCode, this.state.new_password)
    .then(data => {
      console.log('ForgotPassword forgotPasswordSubmit data', data);
      const { history } = AppState;
      history.push('/signin');
     })
    .catch(err => {
      console.log('ForgotPassword forgotPasswordSubmit err', err);
      const { set_prop } = AppState;
      let AlertBanner = {visible : true, color : 'danger', text : err.message}
      set_prop("AlertBanner", AlertBanner);      
      if (err.code === "CodeMismatchException") {
        this.setState({ showCode: false });
      }
    });
  }

	render() {
    const { showCode } = this.state
	  return (
      <div>
        <Header />
			  <div className="content">
  	      <Row>
  					<Col xs={{ span: 10, offset: 1 }} sm={{ span: 6, offset: 3 }} md={{ span: 4, offset: 4 }}>
              <div>
          { !showCode && (
                <div>
      						<h2>Forgot Password</h2>
      			      <Form onSubmit={ this.forgotPassword }>
      			        <Form.Group>
      			          <Form.Label for="username">User name</Form.Label>
      			          <Form.Control type="text" name="username" id="username" placeholder="" onChange={this.handleChange} required />
      			        </Form.Group>
      			        <Button color="primary" type="submit">Submit</Button>
      			      </Form>
                </div>
            )
          }
          {showCode && (
                <div>
      						<h2>Forgot Password</h2>
      			      <Form onSubmit={ this.forgotPasswordSubmit }>
      			        <Form.Group>
      			          <Form.Label for="authCode">Code</Form.Label>
      			          <Form.Control type="text" name="authCode" id="authCode" placeholder="" onChange={this.handleChange} required />
                      <Form.Text>Check your spam folder if you do not see "Your verification code" email</Form.Text>
                    </Form.Group>
      			        <Form.Group>
      			          <Form.Label for="new_password">New Password</Form.Label>
      			          <Form.Control type="password" name="new_password" id="new_password" placeholder="" onChange={this.handleChange} required />
      			        </Form.Group>
      			        <Button color="primary" type="submit">Submit</Button>
      			      </Form>
                </div>
            )
          }
              </div>
  					</Col>
  				</Row>
  			</div>
        <Footer />
      </div>
	  );
	}
};

export default ForgotPassword;
