// import React from 'react';
// import { Row, Col, Form, FormGroup, Label, Input, Button, FormText } from 'reactstrap';
import GenFormState from './GenFormState';

export {
  processSubmit,
};

// FormGroup
const processSubmit = function (event) {  
  event.preventDefault();
  const { formData, jsonb_schema_form_layout } = GenFormState;
	console.log('processSubmit formData', formData);
  let json_data = {};
  // NOTE: This should be refactored as a recursive function if we want to support multiple levels of nesting.
  // Build a hierarchical object to account for the arrays
  for(let key in formData){
    console.log('processSubmit key', key, ' - val', formData[key], ' - formData', formData)
    console.log(typeof key, '-', key.split('.'));
    let key_array = key.split('.');
    if(key_array.length === 1){
      json_data[key] = formData[key];
    }else{
      // NOTE: We currently support only one level so there always be 2 items in the array.
      let curr_obj = {}, fieldname = '', index = 0, match_index = 0;
      // Set the field name
      let re = /\[\s*(\d+)\s*\]/g;
      let match = re.exec(key_array[0]);
      console.log('processSubmit match', match)
      console.log('processSubmit match1', match[1], '- index', match.index)
      index = match[1];
      match_index = match.index;
      fieldname = key_array[0].substring(0,match.index - 1);
      console.log('processSubmit fieldname', fieldname)
      // Set the key.
      if(!json_data[fieldname]) json_data[fieldname] = []
      // Build the object.
      if(! json_data[fieldname][index]) json_data[fieldname][index] = {}
      json_data[fieldname][index][key_array[1]] = formData[key];
    }
  }
  if(formData.id){
    json_data.id = formData.id;
  }else{
    if(! jsonb_schema_form_layout.id_is_auto_generated){
      formData.id  = Math.uuid();  // Loaded in /public
      json_data.id = formData.id;
    }
  }
  console.log('formData', formData, '- json_data', json_data)
  console.log('processSubmit jsonb_schema_form_layout.id_is_auto_generated', jsonb_schema_form_layout.id_is_auto_generated)
  if(! jsonb_schema_form_layout.id_is_auto_generated && ! formData.id){
    return {id : json_data.id, json_data : json_data, formData : formData}
  }else{
    if(formData.id){
      return {id : formData.id, json_data : json_data, formData : formData}
    }else{
      return {json_data : json_data, formData : formData}
    }
  }
}
