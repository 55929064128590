import React from "react";
import { Row, Col, Button, Form } from 'react-bootstrap';
import { Auth } from 'aws-amplify';
import Header from "../partials/Header";
import Footer from "../partials/Footer";
import AppState from '../../support/AppState';
import { Link } from 'react-router-dom'

class SignIn extends React.Component {
	
  state = {
    username: '',
    password: '',
    authCode: '',
    showSingUpConfirmation: false
  }
  
  componentWillUnmount() {
    const { resetAlertBanner } = AppState;
    resetAlertBanner();
  }
  
  goToSignUp = () => {
    const { history } = AppState;
    history.push('/signup');
  }
  
  handleChange = (event) => {
		this.setState({[event.target.id] : event.target.value})
  }

  signIn = (event) => {
    event.preventDefault();
    const { history } = AppState;
    Auth.signIn(this.state.username, this.state.password)
      .then(user => {
        // MFA is not on by default. See Privato⁩/IT⁩/_CodeCommit⁩/Cognito⁩/ReactAmplifyAuth⁩/react-auth⁩/⁨src⁩/SignIn.js
        // this.setState({ user, showConfirmation: true })
        console.log('SignIn handleSubmit user', user);
        const { set_prop } = AppState;
        set_prop("user", user);
        set_prop("isAuthenticated", true);
        // Redirect reset to default.
        let redirect_login_url = AppState.redirect_login_url;
        AppState.redirect_login_url = '/';
        history.push(redirect_login_url);
      })
      .catch(err => {
        const { set_prop } = AppState;
        let AlertBanner = {visible : true, color : 'danger', text : err.message}
        set_prop("AlertBanner", AlertBanner);
        if (err.code === 'UserNotConfirmedException') {
          // The error happens if the user didn't finish the confirmation step when signing up
          // In this case you need to resend the code and confirm the user
          // About how to resend the code and confirm the user, please check the signUp part
          this.setState({showSingUpConfirmation : true});
        } else if (err.code === 'PasswordResetRequiredException') {
          // The error happens when the password is reset in the Cognito console
          // In this case you need to call forgotPassword to reset the password
          // Please check the Forgot Password part.
          const { history } = AppState;
          history.push('/forgotpassword');
        } else if (err.code === 'NotAuthorizedException') {
            // The error happens when the incorrect password is provided
        } else if (err.code === 'UserNotFoundException') {
            // The error happens when the supplied username/email does not exist in the Cognito user pool
        }
      });
  }
  
  // This handles the error UserNotConfirmedException
  confirmSignUp = (event) => {
    event.preventDefault();
    Auth.confirmSignUp(this.state.username, this.state.authCode)
    .then(data => {
      // TODO: verify if success is a string and not a boolean.
      console.log('SignUp confirmSignUp data', data);
      if(data === 'SUCCESS'){
        this.setState({showSingUpConfirmation : false});
        const { history } = AppState;
        history.push('/signin');
      }else{
        alert('Bad code');
      }
     })
    .catch(err => {
      console.log('SignUp confirmSignUp err', err);
      const { set_prop } = AppState;
      let AlertBanner = {visible : true, color : 'danger', text : err.message}
      set_prop("AlertBanner", AlertBanner); 
    }); 
  }
  resendSignUp = () => {
    const { set_prop } = AppState;
    Auth.resendSignUp(this.state.username).then(() => {
      let AlertBanner = {visible : true, color : 'success', text : 'Code resent successfully.'}
      set_prop("AlertBanner", AlertBanner);
    })
    .catch(err => {
      console.log('SignUp resendSignUp err', err);
      let AlertBanner = {visible : true, color : 'danger', text : err.message}
      set_prop("AlertBanner", AlertBanner); 
    }); 
  }

	render() {
    const { showSingUpConfirmation } = this.state;
	  return (
      <div>
        <Header />
			  <div className="content">
  	      <Row>
  					<Col xs={{ span: 10, offset: 1 }} sm={{ span: 6, offset: 3 }} md={{ span: 4, offset: 4 }}>
      { !showSingUpConfirmation && (
              <div>
    						<h2>Sign In</h2>
    			      <Form onSubmit={ this.signIn }>
    			        <Form.Group>
    			          <Form.Label for="username">User name</Form.Label>
    			          <Form.Control type="text" name="username" id="username" placeholder="" onChange={this.handleChange} required />
    			        </Form.Group>
    			        <Form.Group>
    			          <Form.Label for="password">Password</Form.Label>
    			          <Form.Control type="password" name="password" id="password" placeholder="" onChange={this.handleChange} required />
    			        </Form.Group>
    			        <Form.Group><Button color="primary" type="submit">Submit</Button></Form.Group>
                  <Form.Group><Link to="/forgotpassword">Forgot Password</Link></Form.Group>
        <Button color="primary" size="sm" onClick={this.goToSignUp}>Register</Button>&nbsp;
                  if you do not have an account.
    			      </Form>
              </div>
      )
    }
    {showSingUpConfirmation && (     
              <div>
        				<h2>Sign Up</h2>
        	      <Form onSubmit={ this.confirmSignUp }>
        	        <Form.Group>
        	          <Form.Label for="authCode">Confirmation Code</Form.Label>
        	          <Form.Control type="text" name="authCode" id="authCode" placeholder="" onChange={this.handleChange} required />
                    <Form.Text>Check your spam folder if you do not see the email</Form.Text>
                  </Form.Group>
                  <Button color="primary">Submit</Button> &nbsp;
                  <Button color="link" onClick={this.resendSignUp}>Resend Code</Button>
        	      </Form>
              </div>
      )
    }      
  					</Col>
  				</Row>
  			</div>
        <Footer />
      </div>
	  );
	}
};

export default SignIn;
