import React from "react";
import { SendRequest } from 'rtcmesh-react';
import { SendRequestAsync } from '../../support/SendRequestAsync';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap'
import Header from "../partials/Header";
import Footer from "../partials/Footer";
import AppState from '../../support/AppState';

class Products extends React.Component {
  constructor(props) {
    if(process.env.REACT_APP_TRACE_FN_SEQ) console.log('=*= Products constructor')
    super(props);
    this.state = { products: [] }
  }
  
  async componentDidMount(){
    if(process.env.REACT_APP_TRACE_FN_SEQ) console.log('=*= Products componentDidMount')
    try{
      await this.load_products();
    } catch (error) {
      console.error(error);
      const { showAlertBanner } = AppState;
      if(showAlertBanner) showAlertBanner('danger', "An error occurred loading the products", 8000);
    }
  }

  load_products = async () => {
    if(process.env.REACT_APP_TRACE_FN_SEQ) console.debug('=*= Products load_products');
    const params = {filter : "data->'data'->'json_data'->>'active' = 'true'"};
    const response =  await SendRequestAsync('retrieve', null, 'products', params);
    console.log('Products load_products response', response)
    if(response.code === 200){
      this.setState({products : response.data.products});
    }
  }
  
  show_product = (id) =>{
    if(process.env.REACT_APP_TRACE_FN_SEQ) console.log('=*= Products show_product')
    this.props.history.push('/product/' + id); 
  }
  
  render_products = () =>{
    const { assets_url_prefix } = AppState;
    let Products = [], columns = [];
    
    this.state.products.forEach((item, idx) => {
      columns.push(
        <Col sm={12} md={4} key={'prodCol' + idx} onClick={() => this.show_product(item.data.id)} className="cursor_pointer">
          <img src={assets_url_prefix + item.data.json_data.id + '.jpg'} className="img-fluid" />
          <div>{item.data.json_data.name}</div>
        </Col>
      );
      // Add a new row after three items or at the end of the array.
      if(idx + 1 % 3 === 0 || idx + 1 == this.state.products.length) {
        Products.push(<Row key={'prodRow' + idx}>{columns}</Row>);
        columns = [];
      }
    });
    return Products;
  }
  
	render() {
    if(process.env.REACT_APP_TRACE_FN_SEQ) console.log('=*= Products render')
    const { assets_url_prefix } = AppState;
    const show_product = this.show_product;
    
	  return (
      <div>
        <Header />
		    <div className="content">
      { ! this.state.products && <h5>Loading...</h5> }
      { this.state.products && this.render_products() } 
		    </div>
        <Footer />
      </div>
	  );
	}
};

export default withRouter(Products);